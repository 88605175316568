import { Extension } from '@tiptap/core'
import { cumulativeSum } from './supportFuncs';

const SwapPara = Extension.create({
    name: 'SwapPara',
  
    addCommands() {
        return {
          swapParagraph: (oldParagraphIndex, newParagraphIndex) => ({ tr, state }) => {
            
            // Paragraph addition logic here
            // You can access the editor state using `state`
            const nodeSizeArray = state.doc.content.content.map((node)=>{return node.nodeSize;} );
            const position_before_paragraph_i = cumulativeSum(nodeSizeArray);
            
         
            // Get the content of the document
            const content = state.doc.content;
        
            // Find the node you want to move
            const nodeToMove = content.child(oldParagraphIndex);
            const movingNodeSize = nodeSizeArray[oldParagraphIndex];


            // If we are moving a paragraph backwards, i.e. newParagraphIndex < oldParagraphIndex (for example oldParagraphIndex = 4, newParagraphIndex = 2) then
            //     1) we will be deleting the paragraph that starts in position_before_paragraph_i[4] and ends in position_before_paragraph_i[5]
            //     2) we will insert a new paragraph in position_befor_paragraph_i[2] so that now this paragraph is paragraph with index 2
            // If we are moving a paragraph forward i.e. newParagraphIndex >= oldParagraphIndex (for example oldParagraphIndex = 3, newParagraphIndex = 5) then
            //     1)  we will be deleting the paragraph that starts in position_before_paragraph_i[3] and ends in position_before_paragraph_i[4]
            //         and we need to remember we did it because it changes the positions of paragraphs!!
            //     2) we will want to insert it as paragraph with index newParagramIndex (i.e. 5), but remember that it's position 5 after we removed paragraph 3, so it's
            //        actually the position before what used to be paragraph 6. but because we also deleted paragraph 3 then the position of paragraph 6 moved backwards by the size of paragraph 3
            const newNodeLocation = newParagraphIndex < oldParagraphIndex ? 
            position_before_paragraph_i[newParagraphIndex] : 
            position_before_paragraph_i[newParagraphIndex+1] - movingNodeSize; 

        
            //Remove the node from its current position
            tr.delete(position_before_paragraph_i[oldParagraphIndex],position_before_paragraph_i[oldParagraphIndex+1]);
        
            // Insert the node at the new position (e.g., at the beginning)
            tr.insert(newNodeLocation, nodeToMove);

            // Apply the transaction to update the document
            let newEditorState = state.apply(tr);

            // Return the updated editor state
            return newEditorState;
          },
        };
      },
  })
  
  export default SwapPara;