import React, {useCallback} from 'react'
import PropTypes from 'prop-types'

import * as Icons from "../Icons";
import { AngleM } from "../../../../layout/Icons";

const EditAngleModal = ({editor, range, inputText, angleText, outputText, setOutputText, closeModal}) => {

  const {from, to} = range;
  const mockClickEffect = useCallback(()=>{
    console.log('Modal button clicked!');

  });

  const replaceText = useCallback(()=>{
    editor.chain().focus().deleteRange({from, to}).insertContentAt(from, outputText).run();
    closeModal();
  });

  return (
    <div className="relative z-10">
       <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-start sm:p-0">
          
          <div className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="mb-4">
                <AngleM content = "Suggested update" />
                <button className="modal-close" type="button" onClick={closeModal}>
                    <Icons.X />
                </button>
                </div>

                <div className="flex w-full space-x-4"> 
                  <div className="flex-1"> 
                    <label className="dropdown-label ">Text to modify</label>
                    <textarea
                        className="dropdown-item mb-2"
                        autoFocus
                        value={inputText}
                        readOnly
                        rows = {10}
                    />
                  </div>
                  <div className="flex-1"> 
                    <label className="dropdown-label ">Updated text</label>
                    <textarea
                        className="dropdown-item mb-2"
                        autoFocus
                        value={outputText}
                        rows = {10}
                        onChange = {(e) => {setOutputText(e.target.value);}}
                    />
                  </div>
                  </div>
                <label className="dropdown-label">Your added angle</label>
                <textarea
                    className="dropdown-item modal-textarea resize-none"
                    autoFocus
                    value={angleText}
                    readOnly
                    rows = {3}
                />
                
                </div>
                <div className="bg-slate-100 px-4 py-1 sm:flex sm:flex-row-reverse sm:px-6">
                  <div className="modal-buttons my">
                      <button type="button" onClick = {closeModal} className="button-clear font-input">
                      Cancel
                      </button>
                      <button className="button-save font-input" type="button" onClick={replaceText}>
                      Add my take
                      </button>
                      
                  </div>
              </div>
            </div>
        </div>
           
               
      </div>
    </div>
  );
}

EditAngleModal.propTypes = {}

export default EditAngleModal