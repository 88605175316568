import { Extension } from '@tiptap/core'
import { v4 as uuidv4 } from 'uuid';
import { cumulativeSum, addTextNodes} from './supportFuncs';

const ReplaceText = Extension.create({
    name: 'replaceText',

    addCommands() {
        return {
            replaceText: (replacementText, from, to, paraIndex) => ({ tr, state}) => {
            
            // Explanation: 
            // `from` and `to` are inter-paragraph indices. 
            // `paraIndex` is the trigger we got back from the textChunk when we clicked the icon.

            
            // We need to:
            // 1. Find thee relevant node, and keep a hold of where it starts.
            // 2. Create a new paragraph node based on the data from the node we're updating.
            // 3. Create a DELETE transaction to delete the original paragraph node.
            // 4. Create an INSERT transaction with our newly created paragraph node.

            
            // 1. Find thee relevant node, and keep a hold of where it starts.
            let nodeSizeArray = state.doc.content.content.map((node)=>{return node.nodeSize;} );
            let position_before_paragraph_i = cumulativeSum(nodeSizeArray);
            const nodeToUpdate = state.doc.content.content[paraIndex];

            // Retrieve paragraph attributes
            const { sourceList, id, extractId } = nodeToUpdate.attrs;

            // Create the new string with the updated text, and a textNode.
             // Create the new text

            // const oldText = nodeToUpdate.textContent;
            // const newText = oldText.slice(0, from) + replacementText + oldText.slice(to);
            
            // const textNode = state.schema.text(newText);

            // Create the new text with the updatted text - while preserving marks
            // 2. Extract the text content and marks before `from` and after `to`.
            const textNodes = [];


            // Add the text nodes before, the replacement text, and after
            textNodes.push(...addTextNodes(nodeToUpdate, 0, from));
            if (replacementText) {
                textNodes.push(state.schema.text(replacementText));
            }
            textNodes.push(...addTextNodes(nodeToUpdate, to, nodeToUpdate.textContent.length));



            // 2. Create a new paragraph node based on the data from the node we're updating.
            const paragraphNode = state.schema.nodes.paragraph.create({
                sourceList: sourceList,  // Initial sourceList with your source
                id: uuidv4(),
                extractId: extractId,
              }, textNodes);
            
            // 3. Create a DELETE transaction to delete the original paragraph node.
            tr.delete(position_before_paragraph_i[paraIndex],position_before_paragraph_i[paraIndex+1]);

            // 4. Create an INSERT transaction with our newly created paragraph node.
            tr.insert(position_before_paragraph_i[paraIndex], paragraphNode);
            

            // Finally: Apply the transaction to the state
            const newState = state.apply(tr);
            
            // Return the new state
            return newState;

            },
        };
    },
});

export default ReplaceText