export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAIL = 'FETCH_FAIL';
export const FETCH_UPDATE = 'FETCH_UPDATE';
export const FETCH_CLEAN = 'FETCH_CLEAN';

export const GIST_SUCCESS = 'GIST_SUCCESS'
export const GIST_FAIL = 'GIST_FAIL';
export const GIST_CLEAN = 'GIST_CLEAN';


export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK';
export const GET_BOOKMARK = 'GET_BOOKMARK';
export const GET_BOOKMARKS = 'GET_BOOKMARKS';
export const BOOKMARK_ERROR = 'BOOKMARK_ERROR';

export const ADD_COLLECTION = 'ADD_COLLECTION';
export const GET_COLLECTION = 'GET_COLLECTION';
export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION'
export const REMOVE_COLLECTION = 'REMOVE_COLLECTION'
export const REMOVE_SAVED_COLLECTION = 'REMOVE_SAVED_COLLECTION'
export const COLLECTION_ERROR = 'COLLECTION_ERROR';
export const CLEAR_COLLECTION = 'CLEAR_COLLECTION';
export const CLEAR_ALL_COLLECTIONS = 'CLEAR_ALL_COLLECTIONS';

export const TRANSFORM_SUCCESS = 'TRANSFORM_SUCCESS';
export const TRANSFORM_FAIL = 'TRANSFORM_FAIL';
export const CLEAR_TRANSFORM = 'CLEAR_TRANSFORM';
export const TRANSFORM_UPDATE_SUCCESS = 'TRANSFORM_UPDATE_SUCCESS'
export const TRANSFORM_UPDATE_FAIL = 'TRANSFORM_UPDATE_FAIL'

export const UPDATE_LOCAL_DRAFT = 'UPDATE_LOCAL_DRAFT';
export const CLEAR_LOCAL_DRAFT = 'CLEAR_LOCAL_DRAFT';
export const LOCAL_DRAFT_ERROR = 'LOCAL_DRAFT_ERROR';

export const UPDATE_TEXT_W_ANGLE = 'UPDATE_TEXT_W_ANGLE';
export const CLEAR_TEXT_ANGLE = 'CLEAR_TEXT_ANGLE';
export const ADD_ANGLE_ERROR = 'ADD_ANGLE_ERROR';

export const SCENARIO_SUCCESS = 'SCENARIO_SUCCESS';
export const SCENARIO_FAIL = 'SCENARIO_FAIL';
export const CLEAR_SCENARIO = 'CLEAR_SCENARIO';
export const SCENARIO_UPDATE_SUCCESS = 'SCENARIO_UPDATE_SUCCESS'
export const SCENARIO_UPDATE_FAIL = 'SCENARIO_UPDATE_FAIL'


