import {
    UPDATE_TEXT_W_ANGLE,
    CLEAR_TEXT_ANGLE,
    ADD_ANGLE_ERROR
} from '../actions/types'

const initialState = {
    plusAngle: null,
    angleLoading: true,
    error: {}
};

function angleReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case UPDATE_TEXT_W_ANGLE:
            return {
                ...state,
                plusAngle: payload,
                angleLoading: false,

            };
        case CLEAR_TEXT_ANGLE:
            return {
                ...state,
                plusAngle: null,
                angleLoading: true,
                error: {}
    
            };
        case ADD_ANGLE_ERROR:
            return {
                ...state,
                plusAngle: null,
                eangleLoading: false,
                error: payload
            }
        default:
            return state;
    }
};

export default angleReducer;