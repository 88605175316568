import { useEffect } from 'react';

const useBackground = (bodyColor, containerColor) => {
  useEffect(() => {
    // Set the background color of the body
    const originalBodyColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = bodyColor;

    // Find the container element and set its background color.Same for navbar
    const container = document.querySelector('.container');
    const navbar = document.querySelector('.navbar');
    const originalContainerColor = container ? container.style.backgroundColor : '';
    const originalNavbarColor = navbar ? navbar.style.backgroundColor : '';
    
    if (container && containerColor) {
      container.style.backgroundColor = containerColor;
    }

    if (bodyColor!== 'white'){
    //Find the navbar element and set its background colour if background colour != white
    navbar.style.backgroundColor = 'white'
    }
    return () => {
      // Reset the body's background color
      document.body.style.backgroundColor = originalBodyColor;

      // Reset the container's background color
      if (container) {
        container.style.backgroundColor = originalContainerColor;
      }
      if (navbar) {
        navbar.style.backgroundColor = originalNavbarColor;
      }
    };
  }, [bodyColor, containerColor]);
};

export default useBackground;