import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types'
import { LazyLoadImage } from 'react-lazy-load-image-component';


import SideText from './homeParts/SideText';


const Section = ({
  containerHeight,
  backgroundImage, 
  overlayImage, 
  additionalStyling,
  titleText,
  subheadingText,
  bulletList,
  positionStyling,
}) => {

  

  return (
    
    <div className="relative w-full h-full" style={{ 'height': containerHeight }} > {/* Container with relative positioning */}
    
    
     
        <div className=' full-split absolute inset-0 z-10'>

            <div className=' background-image-align w-full h-full  '>
            <LazyLoadImage 
                                    src={backgroundImage} 
                                    alt="Decorative image" 
                                    className={`hidden md:flex ${additionalStyling}`} 
                                    />
        </div> 
        </div>
      

      
  
    {/* Halves Split */}
    <div className='relative md:absolute inset-0 z-20'>
      <div className='full-split md:half-split  inset-0 '>
        <div className='w-full h-full pt-12 '> 
            <SideText 
              titleText = {titleText}
              subheadingText = {subheadingText}
              bulletList={bulletList}
            />

        </div> 

        <div className='hidden md:flex w-full h-full'></div> 
      </div>
      </div>
      {/* New Top Layer */}
      <div className="two-thirds-right-split absolute inset-0 z-50"> {/* Ensure z-index is higher than other layers */}
        {/* Content of your top layer */}
        <div className='w-full h-full'></div> 
         <div className='app-image-align w-full h-full  '> 
          <div className='image-size-control'>
            <LazyLoadImage
              src={overlayImage}
              alt="Top Layer Image"
              className="hidden md:flex top-layer-image rounded-lg outline outline-offset-1 outline-1 outline-zinc-400 shadow-lg "  // Adjust styling as necessary
            />
          </div>
        </div>
      </div>
     
</div>
  );
};

Section.propTypes = {
  containerHeight: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  overlayImage: PropTypes.string.isRequired,
  additionalClass:PropTypes.string,
  titleText: PropTypes.string,
  subheadingText: PropTypes.string,
  bulletList: PropTypes.arrayOf(PropTypes.string).isRequired,
  positionStyling: PropTypes.string
}
export default Section;




