import {
    UPDATE_LOCAL_DRAFT,
    CLEAR_LOCAL_DRAFT,
    LOCAL_DRAFT_ERROR
} from '../actions/types'

const initialState = {
    editorState: null,
    editorLoading: true,
    error: {}
};

function draftReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case UPDATE_LOCAL_DRAFT:
            return {
                ...state,
                editorState: payload,
                editorLoading: false,

            };
        case CLEAR_LOCAL_DRAFT:
            return {
                ...state,
                editorState: null,
                editorLoading: true,
    
            };
        case LOCAL_DRAFT_ERROR:
            return {
                ...state,
                editorState: null,
                editorLoading: false,
                error: payload
            }
        default:
            return state;
    }
};

export default draftReducer;