import React from 'react'
import PropTypes from 'prop-types'

import Bullets from './Bullets'

const SideText = ({titleText, subheadingText, bulletList}) => {
  return (
    <div>
    <div className='font-special text-zinc-800/80  text-3xl lg:text-4xl '>{titleText}</div>
    <div className='font-semibold text-zinc-800/80 my-1 text-2xl '>{subheadingText}</div>
    <div className='text-lg lg:text-xl pt-2'> 
    <Bullets  
           bulletList = {bulletList}    
            />
    </div>
    
    </div>
  )
}

SideText.propTypes = {
    titleText: PropTypes.string,
    subheadingText: PropTypes.string,
    bulletList: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default SideText