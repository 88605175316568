import {
    GIST_SUCCESS,
    GIST_FAIL,
    GIST_CLEAN
} from '../actions/types';

const initialState = {
    content: null,
    gistSuccess: null,
    loading: true
};

function gistReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case GIST_SUCCESS:
            return {
                ...state,
                content: payload,
                gistSuccess: true,
                loading: false,      

            }
        case GIST_FAIL:
            return {
                ...state,
                content: null,
                gistSuccess: false,
                loading: false,
                
            }
        case GIST_CLEAN:
            return {
                ...state,
                content: null,
                gistSuccess: null,
                loading: true

            }
        default:
            return state;
    }

};

export default gistReducer;