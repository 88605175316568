import api from '../utils/api';
import { setAlert } from './alert';

import {
    GIST_SUCCESS,
    GIST_FAIL,
    GIST_CLEAN
} from './types';

// summarise article

export const gist = (text, title, description, url) => async (dispatch) => {
    const body = { text, title, description, url } 
    
    try {
        const res = await api.post('/gist', body);
        const fullData = {
            ...res.data,
            title,
            description,
            url
        }

        dispatch({
            type: GIST_SUCCESS,
            payload: fullData
          });
    
    } catch (err) {
        const errors = err.response.data.errors;
  
        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
        }
  
        dispatch({
            type: GIST_FAIL
      });
    }
};

export const cleanGist = () => dispatch => {
    dispatch({ type: GIST_CLEAN}); 
};

