import React, {Fragment} from 'react'

const Hero = () => {
  return (
    <Fragment>
        <div className='pt-14 '>
        {/* bg-gradient-to-br from-fuchsia-200 via-white  */}
            <div className='home-container center pt-8'>

                {/* <div className='center text-9xl font-regular text-gradient from-text-zinc-800/80'>
                    <span className='font-handwritten text-transparent bg-clip-text bg-gradient-to-b from-zinc-800 to-zinc-400'> 'Content'</span>
                </div>

                 <div className='center mt-4 text-5xl font-extrabold text-zinc-800/80'>
                 No more.
                </div> */}

                <div className='text-center mt-10 py-1 text-8xl font-special text-transparent bg-clip-text bg-gradient-to-br from-purple-900 to-orange-500'>
                    Expert Financial Writing
                </div>
                <div className='text-center mt-5 mb-1 pb-16 text-6xl font-special text-zinc-800/80'>
                    In just three clicks.
                </div>

                {/* <div className='text-center mt-10 text-4xl font-special text-zinc-800/80'>
                    Engage clients with worthy marketing,
                </div> */}
                {/* <div className='text-center py-1 text-8xl font-special text-transparent bg-clip-text bg-gradient-to-br from-purple-800 to-orange-500'>
                    over and over again.
                </div> */}
                {/* <div className='text-center pt-16 text-2xl font-special text-zinc-700/80'>
                   ( All it takes is a click, a drag and a drop. )
                </div>   */}
            </div>
        </div>
    </Fragment>
  )
}

export default Hero