import React, { Fragment, useState, useEffect } from 'react'
import { Link, Navigate } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetch, cleanFetch } from '../../actions/fetch';
import {cleanGist} from '../../actions/gist';

import { Get} from '../layout/Icons';
import MessageDisplay from '../layout/MessageDisplay';

const Fetch = ({fetchLoading, fetchSuccess, fetch, cleanFetch, cleanGist}) => {

  // We start off having not clicked the button.
  // This is to solve the problem where some data may exist in the Redux state.
  const [navigateData, setNavigateData] = useState({
    toggle: false,
    clicked: false
  });
  const {toggle, clicked} = navigateData;
  
  // Form and message states
  const [url, setUrl] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  
  const onSubmit = (e) => {
    e.preventDefault();

    //1. Now, we have clicked the button
    setNavigateData({...navigateData,clicked:true});

    // 2. By virtue of clicking 'Fetch', we're done with whatever we had before.
    // Fetch takes care of the clean in the fetch action.
    cleanGist();

    // 3. Let's get to it
    setShowMessage(true);
    setMessage("Fetching article...");
    fetch(url);
    setMessage("Crunching text...");
    
  };
 
  useEffect(() => {
    if (!clicked && !fetchLoading) { //There was something in fetch before we even got here
      cleanFetch();
    }
    if(clicked && !fetchLoading && fetchSuccess){ //Button clicked AND fetch success
      setNavigateData({...navigateData, toggle:true});
    }
    if (clicked && !fetchLoading && fetchSuccess === false ){ //We clicked. We failed. 
      setShowMessage(false);
      setMessage("");
      setNavigateData({...navigateData, toggle:false});
      setUrl("");
    }
    
     }, [fetchLoading, fetchSuccess ]);

  // We navigate to clipper only if the fetch action got called AND completed
  if (clicked && toggle) {
    return <Navigate to="/clipper" />;
  }


  return (
    <Fragment>
    <div className="flex justify-center pt-10 items-start bg-white"> 
    {/* min-h-screen */}
      {/* Box that takes up 3/5 of the screen for screens over 640px */}
      <div className="sm:w-3/4 bg-white rounded-md shadow-md overflow-hidden border border-gray-200">
        
        {/* White Content Section */}
        <div className="bg-white px-4 py-5 sm:px-6 text-center">
          <Get content="Fetch Article Text" /> 
          
          <form className="form sm:text-left" onSubmit={onSubmit}>
            <div className="form-group">
              <label className='text-sm text-purple-600'>Artice link:</label>  
              <input
                type="url"
                name="url"
                value={url}
                onChange={e => setUrl(e.target.value)}
                className="mt-2 url"
                placeholder="https://..."
              />
            </div>
          </form>
        </div>

        {/* Gray Footer Section */}
        <div className="bg-gray-100 px-4 py-6 sm:px-6 sm:flex sm:flex-row-reverse justify-between">
          <form className="form" onSubmit={onSubmit}>
           
            <input
              type="submit"
              value="Fetch Article"
              className="btn btn-secondary"
            />
             <Link to="/gist" className="btn">Copy-Paste Text</Link>
          </form>
          {showMessage && message && <MessageDisplay message={message} />}
        </div>
      </div>
    </div>
  </Fragment>

    )



   
  
}


const mapStateToProps = state => ({
    fetchSuccess: state.fetch.fetchSuccess,
    fetchLoading: state.fetch.loading
  });


Fetch.propTypes = {
  fetchSuccess: PropTypes.bool,
  fetchLoading: PropTypes.bool.isRequired,
  fetch: PropTypes.func.isRequired,
  cleanFetch: PropTypes.func.isRequired,
  cleanGist: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, {fetch, cleanFetch, cleanGist})(Fetch);