import { Extension } from '@tiptap/core'
import cloneDeep from 'lodash/cloneDeep';
import { mergeExtracts } from './supportFuncs';
// import { cumulativeSum, findParaNum } from './supportFuncs'

// This is an example of how you'll be able to store a list of urls

const ExtractCache = Extension.create({
  name: 'extractCache',

  addStorage() {
    return {
      extractObj: {},
     
    }
  },

  addCommands() {
    return {
        addExtract: (inputExtractObj) => ({ tr, state, editor }) => {
            //inputExtractObj: { _id, all the other stuff: url, title, genParag, altParags}
            
            // Extract addition logic here. Notice that if an Extract already existed, we will override it
            // With the new addition. THIS IS OKAY because altParags may not have updated the first time we load
            // The containing collction
            // On the other hand, if there really were no alternatives and we generated them through the modal:

            // What you want is to compare the old extract with the new extract and determine which is richer.
            // Because we may have generated altParags earlier, for example, making the cached version richer
            // in one dimension but not in another.
            if (inputExtractObj._id in editor.storage.extractCache.extractObj){
                const cachedObj = editor.storage.extractCache.extractObj[inputExtractObj._id] // the thng exists
                const newExtractObj = mergeExtracts(cachedObj,inputExtractObj); //we obtain a "maximum" of the two
                editor.storage.extractCache.extractObj[inputExtractObj._id] = newExtractObj;
                let newEditorState = state.apply(tr);
                return newEditorState; // updating state but without doing anything. In case we're counting on a refresh to editor 
              } else {
              
              const clonedExtractObj = cloneDeep(inputExtractObj);
              editor.storage.extractCache.extractObj[inputExtractObj._id] = clonedExtractObj;
              // Apply the transaction to update the document
              let newEditorState = state.apply(tr);

              // Return the updated editor state
              return newEditorState;
            }

             

            
          },
        addExtracts: (extracts) => ({ tr, state, editor }) => { //@TODO
            // Ensure 'sources' is an array
            if (!Array.isArray(extracts)) {
                console.error('Sources should be an array');
                return state.tr; // Return the unchanged transaction if 'sources' is not an array
            }

            // Iterate over the array of extracts and insert each one
            
            extracts.forEach(extract => {
                const clonedExtract = cloneDeep(extract); // Clone each extract
                editor.storage.extractCache.extractObj[extract._id] = clonedExtract;
                
            });

            // Apply the transaction to update the document
            let newEditorState = state.apply(tr);

            // Return the updated editor state
            return newEditorState;
        },
        updateExtractAlts: (altsArray,extractId) => ({tr, state, editor}) => {
          //if the id is for whatever reason not in the editor cache, console.log an error.
          // This shouldn't happen.
          if (!Array.isArray(altsArray)) {
            console.error('Alts should be an array');
            return state.tr; // Return the unchanged transaction if 'sources' is not an array
          }

          // Clone the entire extract object to avoid any mutations
          const existingExtractObj = editor.storage.extractCache.extractObj[extractId];

          if (!existingExtractObj) {
            // Return the unchanged transaction if the extract ID is not found. Not supposed to happen
            console.error(`Extract with ID ${extractId} not found in cache`);
            return state.tr; 
          }
          
          // Clone the existing extract object deeply
          const clonedExtractObj = cloneDeep(existingExtractObj);

          // Update the altParags with the new altsArray
          clonedExtractObj.altParags = [...altsArray]; // Clone the array to avoid mutation
          
          // Replace the old extract object with the updated cloned object
          editor.storage.extractCache.extractObj[extractId] = clonedExtractObj;
          
          let newEditorState = state.apply(tr);
          return newEditorState;

        },
        updateExtractScenarios: (scenarioObj,extractId) => ({tr, state, editor}) => {
          // Clone the entire extract object to avoid any mutations
          const existingExtractObj = editor.storage.extractCache.extractObj[extractId];

          if (!existingExtractObj) {
            // Return the unchanged transaction if the extract ID is not found. Not supposed to happen
            console.error(`Extract with ID ${extractId} not found in cache`);
            return state.tr; 
          }
          
          // Clone the existing extract object deeply
          const clonedExtractObj = cloneDeep(existingExtractObj);
          const clonedScenarioObj = cloneDeep(scenarioObj) // Clone the scenario object to avoid mutation
          // Update the sceenarios with the new object
          clonedExtractObj.scenarios = clonedScenarioObj; 
          
          // Replace the old extract object with the updated cloned object
          editor.storage.extractCache.extractObj[extractId] = clonedExtractObj;
          
          let newEditorState = state.apply(tr);
          return newEditorState;

        },        
        deleteExtract: (paragraphIndex) => ({tr, state, editor}) => {
            
            const nodeExtractArray = state.doc.content.content.map((node)=>{return node.attrs.extractId;} );
            const candidateExtract = nodeExtractArray[paragraphIndex];

            let countForEach = 0;
            nodeExtractArray.forEach(element => {
                if (element === candidateExtract) {
                    countForEach++;
                }
            });
        
            if (countForEach===1) {
            
            // There is exactly one therefore we can delete safely:
              delete editor.storage.extractCache.extractObj[candidateExtract];

              // Apply the transaction to update the document
              let newEditorState = state.apply(tr);
          
              // Return the updated editor state
              return newEditorState;
          }
        },
        clearDocExtracts: () => ({ tr, state, editor }) => {
          editor.storage.extractCache.extractObj = {};

          // Apply the transaction to update the document
          let newEditorState = state.apply(tr);

          // Return the updated editor state
          return newEditorState;
        },
        
    }},
})

export default ExtractCache