import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { useEditorContext } from './EditorContextProvider';


// The Tooltip component 
const Tooltip = ({ tooltipText }) => {
      return (
          <div className="tooltip">
            {tooltipText}
          </div>
        );
};
  
// The ButtonWithTooltip component 
const ButtonWithTooltip = ({ onClick, tooltipText, children, ...rest }) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  
    return (
          <div
            style={{ position: 'relative'}}
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
            onClick={onClick} // Attach onClick here so it can handle clicks on the whole wrapper
            {...rest} // Spread other props like `data-what-signal`
          >
            {/* <button onClick={onClick} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
              {children}
            {/* </button> */}
            {isTooltipVisible && <Tooltip tooltipText={tooltipText} />}
          </div>
        );
};

const TextChunk = React.memo(({content, id, containsGenParag, index, provided, extractOrigin}) => {

    const { 
      deleteChunk,
      setSignalFromChunk,
      signalTypes,
    } = useEditorContext();

    const [seeDelete, setSeeDelete] = useState(false);
    // const [editedText, setEditedText] = useState(content);

    const updateTools = () => setSeeDelete(prev => !prev);

    const remove = () => {
      deleteChunk(id);
    };

    const sendBackSignal = (event) => {
      const whatSignal = event.currentTarget.getAttribute('data-what-signal');
      setSignalFromChunk({who: index, what: whatSignal});
    };




    return <Fragment>
          {(
          <div
            onMouseEnter={updateTools}
            onMouseLeave={updateTools}
            className ="w-full summary-sent-white my pb-2"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >

          <div className="p ">{content}</div>
          <div className='tools'>

          {seeDelete && containsGenParag && 
            <ButtonWithTooltip onClick={sendBackSignal} tooltipText = {'Rephrase'} data-what-signal = {signalTypes.alts}>
              <div className='tool-btn'>
                <i className="fas fa-square-pen"></i>
              </div>
            </ButtonWithTooltip>
          }

          {seeDelete && extractOrigin &&
            <ButtonWithTooltip onClick={sendBackSignal} tooltipText = {'Connect'} data-what-signal = {signalTypes.scenarios}>
              <div className='tool-btn'>
                <i className="fas fa-circle-dot"></i>
              </div>
            </ButtonWithTooltip>
            } 

          {seeDelete && 
            <ButtonWithTooltip onClick={remove} tooltipText = {'Remove'}>
              <div className='tool-btn'>
                <i className="fas fa-circle-xmark"></i>
              </div>
            </ButtonWithTooltip>
          }
           
         </div> 
        
        </div>
        )}
      </Fragment> 
});



TextChunk.propTypes = {
    content: PropTypes.string.isRequired,
    provided: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
  };
  
  
  export default TextChunk;



          // <i className="fas fa-circle-xmark"></i>
            //           {/* <button onClick={remove} className='tool-btn'>
                
            // </button> */}

      //       <button onClick={sendBackSignal} className='tool-btn' data-what-signal = {signalTypes.alts}>
      //       <i className="fas fa-square-pen"></i>
      //     </button>

      //   <ButtonWithTooltip onClick={sendBackSignal} tooltipText = {'Rephrase'} data-what-signal = {signalTypes.alts}>
      //   <div className='tool-btn'>
      //     <i className="fas fa-square-pen"></i>
      //   </div>
      // </ButtonWithTooltip>

  //     <button onClick={sendBackSignal} className='tool-btn' data-what-signal = {signalTypes.scenarios}>
  //     <i className="fas fa-circle-dot"></i>
  // </button>