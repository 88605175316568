import api from '../utils/api';

import {
    UPDATE_TEXT_W_ANGLE,
    CLEAR_TEXT_ANGLE,
    ADD_ANGLE_ERROR,
    SCENARIO_SUCCESS,
    SCENARIO_FAIL,
    CLEAR_SCENARIO,
    SCENARIO_UPDATE_SUCCESS,
    SCENARIO_UPDATE_FAIL
} from './types';


export const generateScenarioText = (extractId, text) => async (dispatch) => {
    const body = {context:text};

    try {
        const res = await api.post('edit/scenarios', body);

        dispatch({type: CLEAR_SCENARIO});
        dispatch({
            type: SCENARIO_SUCCESS,
            payload: res.data
          });

        if(res.data){
            try {
              // console.log(`Calling PUT /extract/alts/${scenarios} with scenarios:`, res.data);
              const extractResponse = await api.put(`/extract/scenarios/${extractId}`, {scenarios: res.data});
              dispatch ({
                type: SCENARIO_UPDATE_SUCCESS,
                payload:extractResponse.data
              });
      
            } catch (err) {
            dispatch({
              type: SCENARIO_UPDATE_FAIL,
              payload: { msg: err.response.statusText, status: err.response.status }
            });
            }
          }

    } catch (err) {
        const errors = err.response.data.errors;
  

        dispatch({
            type: SCENARIO_FAIL,
            payload: { msg: 'Failed to add scenarios to text', status: '409' }
      });
    }
};


export const clearScenarios = () => dispatch => {
    dispatch({ type: CLEAR_SCENARIO}); 
};



export const addAngle = (angle,text)=> async (dispatch) => {
    const body = { angle,text };

    try {
        const res = await api.post('/edit/angle', body);

        dispatch({type: CLEAR_TEXT_ANGLE});
        dispatch({
            type: UPDATE_TEXT_W_ANGLE,
            payload: res.data
          });
    
    } catch (err) {
        const errors = err.response.data.errors;
  

        dispatch({
            type: ADD_ANGLE_ERROR,
            payload: { msg: 'Failed to add angle to text', status: '409' }
      });
    }
};

export const clearAngleText = () => async(dispatch) => {
    dispatch({type: CLEAR_TEXT_ANGLE});
}

