import { Extension } from '@tiptap/core'
import { cumulativeSum } from './supportFuncs';

const DeletePara = Extension.create({
    name: 'DeletePara',
  
    addCommands() {
        return {
          deleteParagraph: (paragraphIndex) => ({ tr, state }) => {
 
            
            // Paragraph addition logic here
            // You can access the editor state using `state`
            const nodeSizeArray = state.doc.content.content.map((node)=>{return node.nodeSize;} );
            const position_before_paragraph_i = cumulativeSum(nodeSizeArray);
            
        
            //Remove the node from its current position
            tr.delete(position_before_paragraph_i[paragraphIndex],position_before_paragraph_i[paragraphIndex+1]);
           
            // After performing the deletion, you should return a new `EditorState` using `tr` and `state.apply`.
            const newEditorState = state.apply(tr);
    
            // Return the updated editor state
            return newEditorState;
          },
        };
      },
  })
  
  export default DeletePara;

        
      