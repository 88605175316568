import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import { clearTransform, createAltParags } from '../../../../../../actions/collection';
import * as Icons from "../../Icons";
import { AngleM } from "../../../../../layout/Icons";
import { useEditorContext } from '../../../../EditorContextProvider';


const ShowAltsModal = ({containsGenParagObj, clearTransform, createAltParags, nlpState}) => {
  const {
    editor,
    closeAltsModal,
    signalTypes,
  } = useEditorContext();

  const {genParag, altsList, from, to, paraIndex} = containsGenParagObj;

  const [localAlts, setLocalAlts] = useState(altsList)
  const [altsExist, setAltsExist] = useState(altsList[0]? true: false);
  const [outputText, setOutputText] = useState(altsList[0]?altsList[0]:"Click button to generate alternatives");
  const [listPosition, setListPosition] = useState(0);

  const currentNode = editor.state.doc.content.content[paraIndex];
  const extractId = currentNode.attrs.extractId;
  const source = editor.storage.extractCache.extractObj[extractId].url;

  useEffect(()=> {

    if(nlpState.paragraph && !nlpState.loading){
      const altList = [...nlpState.paragraph]; // Clone the array to avoid passing by reference
      setLocalAlts(altList);
      setOutputText(altList[0]);
      setAltsExist(true);
      clearTransform();
    }
},[nlpState]);

// editor.commands.updateExtractAlts(altList, extractId);
// We can't call this in the modal, because it's mutating the state of the editor while the modal is open.
  
  useEffect (()=>{
    setOutputText(localAlts[listPosition]);
  }, [listPosition]);

  const clickForwards = () => {
    const currentIndex = listPosition
    setListPosition((currentIndex+1) % localAlts.length);
  }

  const clickBackwards = () => {
    const currentIndex = listPosition
    setOutputText(localAlts[(currentIndex + localAlts.length -1) % localAlts.length]);
    setListPosition((currentIndex + localAlts.length-1) % localAlts.length);
  }


  
  // const mockClickEffect = ()=>{
  //   console.log('Modal button clicked!');

  // };

  const replaceText = ()=>{
    const data = {
      packet: altsExist? [...localAlts] : null, //clone the array to avoid passing by reference
      extractId: extractId,
      fieldType: signalTypes.alts
    };
    editor.commands.replaceText(outputText, from, to, paraIndex);
    closeAltsModal(data);

  };

  const insertBelow = ()=>{
    const data = {
      packet: altsExist? [...localAlts] : null, //clone the array to avoid passing by reference
      extractId: extractId,
      fieldType: signalTypes.alts
    };
    editor.commands.addParagraph(outputText, source, paraIndex+1, extractId);
    closeAltsModal(data);
  };

  const closeThisModal = ()=>{
    const data = {
      packet: altsExist? [...localAlts] : null, //clone the array to avoid passing by reference
      extractId: extractId,
      fieldType: signalTypes.alts
    };
    closeAltsModal(data);
  };

  const createOnSpot = ()=> {
    clearTransform();
    //set something
    const title  = editor.storage.extractCache.extractObj[extractId].title;
    const snippets = editor.storage.extractCache.extractObj[extractId].snippets;
    createAltParags(extractId, title, snippets);
    //set something
  };

  return (
    <div className="relative z-10">
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
     <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
         <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
       
       <div className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-4xl">
           <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
             <div className="mb-4">
             <AngleM content = "Idea rephrase" />
             <button className="modal-close" type="button" onClick={closeThisModal}>
                 <Icons.X />
             </button>
             </div>

            
             <label className="dropdown-label ">First version</label>
             <div className='flex '>
             <textarea
                 className="dropdown-item mb-2 "
                 autoFocus
                 value={genParag}
                 readOnly
                 rows = {5}
             />
            </div>

             <label className="dropdown-label">Alternative</label>
             <div className='flex'>
              <div className='w-1/10 tools'>
                <button onClick = {clickBackwards} className='tool-btn'><i className="fa-solid fa-circle-arrow-left"></i></button>
              </div>
              <textarea
                  className="dropdown-item modal-textarea"
                  autoFocus
                  value={outputText}
                  readOnly
                  //  onChange = {(e) => {console.log("change");}}
                  rows = {5}
              />
              <div className='w-1/10 tools'>
                <button onClick = {clickForwards} className='tool-btn'><i className="fa-solid fa-circle-arrow-right"></i></button>
              </div>
              </div>
             </div>

             <div className="bg-slate-100 px-4 py-1 sm:flex sm:flex-row-reverse sm:px-6">
               <div className="modal-buttons my">
                  {!altsExist &&
                  <button className="button-save font-input" type="button" onClick={createOnSpot}>
                   Generate New
                   </button>
                  }
                   {altsExist &&
                   <button type="button" onClick = {insertBelow} className="button-clear font-input">
                   Insert Below
                   </button>
                   }
                   {altsExist &&
                   <button className="button-save font-input" type="button" onClick={replaceText}>
                   Replace
                   </button>
                  }
                   
               </div>
           </div>
         </div>
     </div>
        
            
   </div>
 </div>
  );
}


const mapStateToProps = state => ({
    nlpState: state.nlp,
});

ShowAltsModal.propTypes = {
  containsGenParagObj:PropTypes.object.isRequired,
  clearTransform: PropTypes.func.isRequired,
  createAltParags: PropTypes.func.isRequired,
}

export default connect(mapStateToProps,{clearTransform, createAltParags})(ShowAltsModal);