import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'


import * as Icons from "../../Icons";
import { BoltM } from '../../../../../layout/Icons';
import StorySelector from './StorySelector';

const BulkWriteModal = ({editor, range, closeModal, startingList}) => {
  const {from, to} = range;

  const [option, setOption] = useState("");
  const [snippets, setSnippets] = useState([]);

  const dataPrep = ()=>{
    const items = startingList
    .filter(obj => obj.extract.genParag) 
    .map(obj => 
      ({
      id: obj._id,
      sent: obj.title,
      text: obj.extract.genParag,
      source: {title: obj.title, url: obj.extract.url},
      extractId: obj.extractPointer,
      extract: obj.extract
      }
      ));
    return items;
  };

  const handleCheckboxChange = (e) => {
    const choice = e.target.value;
    let existingChoice = option!==""? true: false

    if (e.target.checked && !existingChoice){
      setOption(choice);
    }
    if (!e.target.checked) {
      setOption("");
    }

  }

  useEffect(() =>{
    setSnippets(dataPrep(startingList));
  },[startingList]);

  const deleteSnippet = (snippetId) => {
    setSnippets((prevSnippets) => prevSnippets.filter((snippet) => snippet.id !== snippetId));
  };

  const updateSnippetOrder = (newSnippets) => {
    setSnippets(newSnippets);
  };



  const replaceText = ()=>{
   
    const sources = snippets.map(item => item.source);
    const extracts = snippets.map(item => item.extract);
   
    if (option === "") {
      editor.commands.addMultipleParagraphs(snippets, 0, false, false);
      editor.commands.addSources(sources);
      editor.commands.addExtracts(extracts);
      closeModal();
    }
    if (option === "titlePlusLinks") {
      editor.commands.addMultipleParagraphs(snippets, 0, false, true);
      editor.commands.addSources(sources);
      editor.commands.addExtracts(extracts);
      closeModal();
    }
  };


  // deleteRange({from, to}).
  


  return (
    <div className="relative z-10 overflow-y-auto">
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
     <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
         <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="mb-4">
                <BoltM content = "Select Stories" />
                <button className="modal-close" type="button" onClick={closeModal}>
                    <Icons.X />
                </button>

                <div className="flex w-full space-x-4 my-4"> 
                <div className="flex-1"> 
                    {/* <label className="dropdown-label mb-3">Updated text</label> */}
                    
                    <StorySelector 
                      summaryContent= {snippets} 
                      updateSnippet = {updateSnippetOrder} 
                      deleteSnippet = {deleteSnippet}
                      />
                    <div className='mt-3'>
                      <input 
                        type="checkbox"  
                        // id = "001" We will have more options. 
                        value="titlePlusLinks"
                        onChange={handleCheckboxChange}
                        checked={(option==="titlePlusLinks")}
                      />
                      <label className='mx-3 text-sm font-semibold leading-6 text-gray-700'> Add a linked title to selected?</label>
                    </div>
                     
                  </div>
                
                </div>

                </div>
              </div>
              <div className="bg-slate-100 px-4 py-1 sm:flex sm:flex-row-reverse sm:px-6">
                  <div className="modal-buttons my">
                      <button type="button" onClick = {closeModal} className="button-clear font-input">
                      Cancel
                      </button>
                      <button className="button-save font-input" type="button" onClick={replaceText}>
                      Add text
                      </button>
                      
                  </div>
              </div>
           </div>
        </div>
    </div>
    </div>
  )
}

BulkWriteModal.propTypes = {}

export default BulkWriteModal

