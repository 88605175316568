import { Extension } from '@tiptap/core'
import { v4 as uuidv4 } from 'uuid';
import { cumulativeSum, removeTrailingPeriodIfAny } from './supportFuncs';


const AddPara = Extension.create({
    name: 'AddPara',
  
    addCommands() {
        return {
          addParagraph: (text, source, paraIndex, extractId, isTitle = false) => ({ tr, state }) => {
            
            // Paragraph addition logic here
            // You can access the editor state using `state`
            const nodeSizeArray = state.doc.content.content.map((node)=>{return node.nodeSize;} );
            const nodeSumsSizes = cumulativeSum(nodeSizeArray);
            const textNode = state.schema.text(text);
            let paragraphNode = {};

            if (isTitle) {
              const linkMark = state.schema.marks.link.create({ href: source.url, target: '_blank' });
              const markedTextNode = textNode.mark(linkMark.addToSet(textNode.marks));
              paragraphNode = state.schema.nodes.paragraph.create({
                sourceList: [source],  // Initial sourceList with your source
                id: uuidv4(),
                extractId: extractId,
              }, markedTextNode);
            }
            else {
              // Create a new paragraph node with the provided text
              paragraphNode = state.schema.nodes.paragraph.create({
                sourceList: [source],  // Initial sourceList with your source
                id: uuidv4(),
                extractId: extractId,
              }, textNode);
            }



            
            // Use `tr.insert` to add the new paragraph at the specified position
            tr.insert(nodeSumsSizes[paraIndex], paragraphNode);
                
            // After performing the addition, you should return a new `EditorState` using `tr` and `state.apply`.
            const newEditorState = state.apply(tr);

            // Return the updated editor state
            return newEditorState;
          },
          addMultipleParagraphs: (items, paraIndex, isTitle = false, appendInitial = false) => ({ tr, state }) => {
            // Calculate the initial insert position based on paraIndex
            const nodeSizeArray = state.doc.content.content.map(node => node.nodeSize);
            const nodeSumsSizes = cumulativeSum(nodeSizeArray);
            let insertPos = nodeSumsSizes[paraIndex];

    
            items.forEach(item => {
                let source = item.source;
                let extractId = item.extractId;
                let contentNodes = [];

                // If appendInitial is true, prepend the title to the text and create a mark for it
                if (appendInitial && source.title) {
                    
                  // Creating the link mark for the title
                  const titleMark = state.schema.marks.link.create({ href: source.url, target: '_blank' });
                  // Creating a text node for the title
                  const titleText = removeTrailingPeriodIfAny(source.title);
                  const titleTextNode = state.schema.text(titleText + ". ", [titleMark]);
                 
                  // Add this title text node to the paragraph's content
                  contentNodes.push(titleTextNode);
                } 

                // Creating a regular text node for the paragraph content and add it to the content
                const paragraphTextNode = state.schema.text(item.text);
                contentNodes.push(paragraphTextNode);

                // Now, create the paragraph node with all its content nodes
                let paragraphNode = state.schema.nodes.paragraph.create({
                  sourceList: [source],
                  id: uuidv4(),  // Ensure uuidv4 is correctly imported or available
                  extractId: extractId
                }, contentNodes); // This now correctly forms a paragraph with inline content

                
                // got rid of the isTitle bit. This is only relevant for a single addSource.
                
                // Insert the new paragraph node at the calculated position
                tr.insert(insertPos, paragraphNode);
    
                // Update insertPos for the next paragraph
                insertPos += paragraphNode.nodeSize;
            });

            // Iterate over each text to create and insert paragraph nodes - WORKING VERSION
            
            // items.forEach(item => {
            //     const textNode = state.schema.text(item.text);
            //     let paragraphNode = {};

            //     let source = item.source;
                
            //     if (isTitle) {
            //         const linkMark = state.schema.marks.link.create({ href: source.url, target: '_blank' });
            //         const markedTextNode = textNode.mark(linkMark.addToSet(textNode.marks));
            //         paragraphNode = state.schema.nodes.paragraph.create({
            //             sourceList: [source],
            //             id: uuidv4()  // Ensure uuidv4 is correctly imported or available
            //         }, markedTextNode);
            //     } else {
            //         paragraphNode = state.schema.nodes.paragraph.create({
            //             sourceList: [source],
            //             id: uuidv4()  // Ensure uuidv4 is correctly imported or available
            //         }, textNode);
            //     }
                
            //     // Insert the new paragraph node at the calculated position
            //     tr.insert(insertPos, paragraphNode);
    
            //     // Update insertPos for the next paragraph
            //     insertPos += paragraphNode.nodeSize;
            // });
          
            // Apply the transaction to the state once after all paragraphs have been added
            const newEditorState = state.apply(tr);
            return newEditorState;
        },
        };
      },
    
  })
  
  export default AddPara;