import React, { useEffect, useState } from 'react';
// import { Link, Navigate } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CollectionItem from './CollectionItem';
import { Stack } from '../../layout/Icons';

import useBackground from '../../layout/utils/useBackground';
import { getCollections } from '../../../actions/collection';


const Collections = ({storedCollectionList, getCollections}) => {

    useBackground('#f1f5f9', '#f1f5f9');
    // useBackground('#f3f4f6', '#f3f4f6');
    const [loaded, setLoaded] = useState(false);

    useEffect(()=> {
        getCollections();
        setLoaded(true);  
    });

    
    return (
        <div>

        <div className='narrow_container surround bg-white'>
            {/* <div className="my p">
                <p className="text-lg text-primary">Collections</p>
            </div> */}

            <div className="py-2">
                <Stack content={"Collections"} />
             </div>
            {loaded && storedCollectionList?.map((collection) => (
                <CollectionItem 
                    key={collection._id}
                    cId = {collection._id} 
                    cTitle = {collection.title} 
                    cUpdate = {collection.updated} 
                    cSize = {collection.bookmarkCount}
                    dishId = {collection.dishPointer}
                    />
            )) }
            
            
        </div>
        </div>   
    );

};

const mapStateToProps = state => ({
    storedCollectionList: state.collection.allCollections
  });

Collections.propTypes = {
    storedCollectionList: PropTypes.array,
    getCollections: PropTypes.func.isRequired  
};

export default connect(mapStateToProps,{getCollections})(Collections);