import React, {Fragment} from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//Notice using backticks!
const Alert = ({alerts}) => {
    
    return (
        <Fragment>
            {alerts !== null && alerts.length> 0 && 
            <div className="rounded-md bg-red-50 p-4 md:w-1/2">
                <div className="flex">
                    <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
                    </svg>
                    </div>
                    <div className="ml-3">
                    <h3 className="text-md font-medium text-red-800">
                    {alerts.length>1? 
                    `Unfortunately, we've encountered the following ${alerts.length} errors`:
                    `Unfortunately, we've encountered the following error`
                    }
                    
                    </h3>
                    <div className="mt-2 text-md text-red-700">
                        <ul className="list-disc space-y-1 pl-5">
                        {alerts.map(alert => (
                            <li key = {alert.id} >  
                                { alert.msg }
                             </li>
                        ))}
                        {/* <li>Your password must be at least 8 characters</li> */}
                        {/* className = {`alert alert-${alert.alertType}`} */}
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
            }
        </Fragment>
)};


Alert.propTypes = {
    alerts: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
    alerts: state.alert
});

export default connect(mapStateToProps)(Alert);
