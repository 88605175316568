import React from 'react'
import { Outlet } from 'react-router-dom'
import Alert from './Alert'

function Container() {
  return (
    <section className="container">
    <div className="sticky top-0 z-50 flex justify-center items-center">
        <Alert />
    </div>
        <Outlet />
    </section>
    
  )
}

export default Container