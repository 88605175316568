import React, { Fragment, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { gist, cleanGist } from '../../actions/gist';
import { cleanFetch } from '../../actions/fetch';

import useBackground from '../layout/utils/useBackground';


const Gist = ({gistLoading, gist, cleanGist, cleanFetch}) => {
  
  // useBackground('#f8fafc', '#f8fafc');
  useBackground('#f1f5f9', '#f1f5f9');
  const [editorData, setEditorData] = useState({
        text: "",
        title: "",
        url:"",
        description: "",
        gistDone: false
    });

  const { text, title, url, description, gistDone } = editorData;

  const onSubmit = (e) => {
    e.preventDefault();
    gist(text, title, description, url);
    setEditorData({...editorData, gistDone:true})
   
  };

  useEffect (() =>{
    cleanGist();
    cleanFetch();
  },[cleanGist, cleanFetch]);

  // the gist action got called AND completed
  if (gistDone && !gistLoading) {
    return <Navigate to="/clipper" />;
  }


  //We're in Gist mode, and that's what our text area looks like.
  const gistArea = (  
    <div className='editor form-group'>
        <textarea
          name='text'
          className='editor editor-textarea font-input'
          placeholder='Paste an article'
          value={text}
          onChange={e => setEditorData({...editorData, text:e.target.value})}
          required
        />
      </div>
  );



  return (
    <Fragment>
        {/* <Link to="/fetch"  className="btn">Back To Fetch</Link> */}
      <form className="summary font-input form" onSubmit={onSubmit}>
       
        <div className="form-group">
          {/* <label className='font-quiet'>Article content</label>   */}
          <Fragment>{gistArea}</Fragment>
          
        </div>
        <div >
          {/* <SummaryBlock summaryContent= {summarySents? summarySents: []} /> */}
         
            <div className="form-group">
                  <label className='font-quiet'>Article title</label>  
                  <input 
                    type="text" 
                    name="title" 
                    value = {title}
                    onChange={e => setEditorData({...editorData, title:e.target.value})}
                    className="url font-input" 
                    placeholder="Article title" 
                    required
                    />
            </div>
            <div className="form-group">
                  <label className='font-quiet'>Source link</label>    
                  <input 
                    label = "url"
                    type="url" 
                    name="title" 
                    value = {url}
                    onChange={e => setEditorData({...editorData, url:e.target.value})}
                    className="url font-input" 
                    placeholder="https://..." 

                    />
            </div>
            <div className="form-group">
                  <label className='font-quiet'>Description</label>      
                  <textarea
                    className='editor-small font-input'
                    type="string" 
                    name="description" 
                    value = {description}
                    onChange={e => setEditorData({...editorData, description:e.target.value})}
                    placeholder="Description" 
                    
                    />
            </div>
            <div className="form-group">
                <input 
                  type="submit" 
                  value="Get Key Points" 
                  className="btn btn-secondary"
                  />
                
            </div>     
      
        </div>
      </form>
    </Fragment>

)
};

// {/* <div className="dash-buttons my-1">
// {/* <Link to="/fetch"  title = "Add bookmark" className="btn btn-light"><i className="fa fa-bookmark text-primary"></i></Link> */}
// <Link to="/fetch"  title = "Add to collection" className="btn btn-light"><i className="fa fa-suitcase text-primary"></i></Link>
// <Link to="/fetch" className="btn btn-light">Add Highlight</Link>
// </div> */}



const mapStateToProps = state => ({
    gistLoading: state.gist.loading
});


Gist.propTypes = {
gist: PropTypes.func.isRequired,
cleanGist: PropTypes.func.isRequired,
cleanFetch: PropTypes.func.isRequired,
gistLoading: PropTypes.bool.isRequired
}


export default connect(mapStateToProps,{gist, cleanGist, cleanFetch})(Gist);
