import React from 'react';
import PropTypes from 'prop-types';



function SnippetViewer({content, provided, colorFlag}) {

  const colorClass = 'summary-sent-'+colorFlag + ' m';
  
  return (
    <div 
        
        className ={colorClass}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        >

        <div className=" p">{content}</div>
    </div>

  )
}


SnippetViewer.propTypes = {
  content: PropTypes.string.isRequired
  }

export default SnippetViewer;

