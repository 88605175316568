import React, { useState } from 'react';



const BinaryButton = ({ onClick, tooltipText, tablerIcon1: TablerIcon1, tablerIcon2: TablerIcon2, size = 32, stroke = 1.25 }) => {
  const [isActive, setIsActive] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  
  const handleClick = () => {
    setIsActive(!isActive); // Toggle the active state
    onClick(); // Call the provided onClick action
  };



  return (
    <div
        style={{ position: 'relative' }} 
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
    >
      {!isActive && <button onClick={handleClick} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <TablerIcon1  size={size} color="#3b424b" stroke={stroke}/>
      </button>}
      {isActive && <button onClick={handleClick} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <TablerIcon2  size={size} color="#3b424b" stroke={stroke} />
      </button>}
      {isTooltipVisible && (
        <div className="tooltip" >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default BinaryButton

// {`binary-button ${isActive ? 'active' : ''}`}

// style={{
//   position: 'absolute',
//   width: 'auto',
// //   bottom: '-100%',
//   left: '50%',
//   transform: 'translateX(-50%) ',
//   backgroundColor: 'gray',
//   fontSize: '0.8rem',
//   color: 'white',
//   padding: '5px',
//   borderRadius: '4px',
// //   marginTop: '10px', // Adjust the space between the tooltip and the button
//   whiteSpace: 'nowrap', // Prevents the text from wrapping
// }}