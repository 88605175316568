import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

function Reader({formattedText, handleHighlight}) {

    return ( 
        <Fragment>
            <div className= "summary-block bg-white p-1">
            <div 
                className= "summary-text p-1" 
                dangerouslySetInnerHTML={{__html:formattedText}}
                onMouseUp={handleHighlight}
                >
                </div>
            </div>
        </Fragment>
  );
};

Reader.propTypes = {
    formattedText: PropTypes.string,
    handleHighlight:PropTypes.func.isRequired    
}

export default Reader


