import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SnippetViewer from './SnippetViewer';

const BookmarkViewer = ({content, parentIndex, provided}) => {

  const title = content.title;
  const extract = content.extract;
  const {snippets, url} = extract;
    
  const [seeDelete, setSeeDelete] = useState(false);
  const [clicked,setClicked] = useState(false)

  const HUNDRED = 100;

  const updateTools = () => {
    setSeeDelete(!seeDelete);
  }

  const open = () => {
    // console.log(content);
    setClicked(true);
  };

  const close = () => {
    setClicked(false)
  };

  return (

        <div className="flex"
        onMouseEnter={updateTools}
        onMouseLeave={updateTools}
        >
          
        <div
        id= 'text'
        className ="w-full summary-sent m"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        >

        <div className=" p">{title}</div>

        {/* here will come the snippets */}
        {clicked && 
          snippets.map((snippet, index) => (
            <Draggable key={snippet._id} draggableId={snippet._id} index={parentIndex+HUNDRED+index}>
                    {(otherProvided) => (
                      <SnippetViewer 
                        key = {snippet._id}   
                        content = {snippet.sent}
                        provided={otherProvided}
                        colorFlag = {snippet.sent === title? 'red': 'blue'}  
                        /> )}
            </Draggable>
        ))}

        </div>
        {seeDelete && !clicked &&
            <div className='w-1/10 tools'>
              <button onClick = {open} className='tool-btn'><i className="fa-solid fa-caret-down"></i></button>
            </div>
          }
          {seeDelete && clicked &&
            <div className='w-1/10 tools'>
              <button onClick = {close} className='tool-btn'><i className="fa-solid fa-caret-up"></i></button>
            </div>
          }   
    </div>
    );

}


const mapStateToProps = state => ({

  });
  
  
BookmarkViewer.propTypes = {
  content: PropTypes.object.isRequired,
  parentIndex: PropTypes.number.isRequired,
  provided: PropTypes.object.isRequired
    
  };
  
  
  export default connect(mapStateToProps,{})(BookmarkViewer);