import React from 'react'


function Catch(props) {
  return (
    <div>Catch</div>
  )
}



export default Catch
