import React from 'react'
import PropTypes from 'prop-types';
import { Bell } from '../Icons'; 

const WarningModal = ({onAccept, blurb, yesButtonText, modalTitle}) => {

    const onClick = () => {
        onAccept();
    }

    return(
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-10"></div>
      
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            
            <div className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <Bell content = {modalTitle} />
                    
                </div>
                <div className="sm:flex sm:items-start pt-5 text-base">
                    <p>{blurb}</p>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-1 sm:flex sm:flex-row-reverse sm:px-6">
                <button type="button" onClick = {onClick} className=" btn btn-success">{yesButtonText}</button>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      
    
    
    
    
    
    )

}



// Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.

WarningModal.propTypes = {
onAccept: PropTypes.func.isRequired,
blurb: PropTypes.string,
yesButtonText: PropTypes.string.isRequired,
modalTitle: PropTypes.string.isRequired
 
};


export default WarningModal;