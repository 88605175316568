import cloneDeep from 'lodash/cloneDeep';

export const isEmpty = (value) => {
  if (value === null || value === undefined || value === '') {
    return true;
  }

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === 'object') {
    // Check if object is empty by recursing through its values
    return Object.keys(value).length === 0 || Object.values(value).every(isEmpty);
  }

  return false; // For other types like numbers, booleans, etc., return false
}

export const cumulativeSum = (myArray)=>{
    const result = [0];
    let sum = 0;
  
    for (let i = 0; i < myArray.length; i++) {
      sum += myArray[i];
      result.push(sum);
    }
  
    return result;
  }

export const findParaNum = (place, position_before_paragraph_i) => {
    
    for (let k = 0; k < position_before_paragraph_i.length-1; k++) {
      const before = position_before_paragraph_i[k];
      const after = position_before_paragraph_i[k+1];
      if (place > before && place < after) {
        return k;
      }
    }
    return -1; //not ever supposed to happen but okay.
  }

export const addTextNodes = (nodeToUpdate, startPos, endPos) => {
  const textNodes = [];
  let pos = 0;

  nodeToUpdate.content.forEach((child) => {
    
    const childSize = child.nodeSize; // text node size is the length of its text content
    console.log(child.nodeSize,"<-->", child.text.length)

    //if (pos >= startPos && pos < endPos) {
    if (pos + childSize > startPos && pos < endPos) {
      const textContent = child.text.slice(Math.max(0, startPos - pos), Math.min(childSize, endPos - pos));
      console.log(pos, textContent, childSize)
      if (textContent) {
        textNodes.push(nodeToUpdate.type.schema.text(textContent, child.marks));
      }
    }
    pos += childSize;

  });

  return textNodes;
};

export const uniqueByOrder = (itemsList)=> {
    const uniqueList = [];
    const seen = new Set();

    for (const item of itemsList) {
      const urlString = item.url;
      if (urlString && !seen.has(urlString)) { //Currently, we're string matching on the URL. It's null on added paragraphs
        uniqueList.push(item);
        seen.add(urlString);
      };
    };
    return uniqueList;
  };

export const capitalizeFirstLetter = (str) => {
    if (typeof str !== "string" || str.length === 0) {
      return str; // Return the original string if it's not a non-empty string
    }
  
    const firstChar = str[0];
  
    if (/[a-zA-Z]/.test(firstChar)) {
      // Check if the first character is a letter
      return firstChar.toUpperCase() + str.slice(1); // Capitalize the first letter and append the rest
    } else {
      return str; // Return the original string if the first character is not a letter
    }
  };

export const urlToName = (url) => {

    let resultString = ""

    const regex1 =  /^(?:https?:\/\/)?(?:www\.)?([^/]+)\./;
    const domainMatch = url.match(regex1);

    if (domainMatch) {
      const extractedPart = domainMatch[1];
      
      resultString += capitalizeFirstLetter(extractedPart);
      // console.log(extractedPart); // This will log "Example"

      const regex2 =  /\/([^/]+)\/?$/;
      const residualMatch = url.match(regex2);
      if (residualMatch) {
        const extractedPart = residualMatch[1];
        const outputString = extractedPart.replace(/[-_]/g, " ");

        resultString = resultString + ": " + capitalizeFirstLetter(outputString)
        // console.log(resultString); // This will log "example"
        return resultString
      }
      else {
        return resultString;
      }
    }
    else {
      return url;
    }
    
  };

export const ensureProtocol = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return 'http://' + url; // Default to http if no protocol is provided
    }
    return url;
  };

export const removeTrailingPeriodIfAny = (text) => {
  if (text.endsWith('.')) {
      // Remove the last character (the period)
      return text.slice(0, -1);
  }
  // Return the original string if it doesn't end with a period
  return text;
}

export const appendSourceToTitle = (sourceList) => {
    //sourceList is a list of objects {title: , url: }
    const appendedTitles = sourceList.map((source)=>{
      const {title, url} = source;
      const nameByURL = urlToName(url);
      if (nameByURL === title) {
        return source; // the source was already inputted directly into the editor
      } else {
        const parts = nameByURL.split(":");
        if (parts.length === 1) {
          // No colon found, meaning the url source hasn't been identified. Return the original string
          return source;
        } else {
          // We append the source to the original title
          const newTitle = parts[0].toUpperCase() + ": " + title;
          return {
            title: newTitle,
            url: url
          };
        }
      }
    });

    return appendedTitles
}

export const mergeExtracts = (cachedExtract, candidateExtract) => {
  // Deep clone the cachedExtract to avoid mutating the original object
  const mergedExtract = cloneDeep(cachedExtract);

  // Iterate over each key in the candidateExtract
  for (const key in candidateExtract) {
    const cachedValue = cachedExtract[key];
    const candidateValue = candidateExtract[key];

    // If the candidate's value is not empty, use it
    if (!isEmpty(candidateValue)) {
      mergedExtract[key] = candidateValue;
    } else if (isEmpty(cachedValue)) {
      // If both values are empty, keep the empty value
      mergedExtract[key] = cachedValue;
    }
    // Otherwise, the cached value is already in place and is non-empty
  }

  return mergedExtract;
}

//********* OLD EDITOR FUNCTIONS. FOR REFERENCE ******* */

// const addPara = useCallback(() => {
    
//     const nodeSizeArray = editor.state.doc.content.content.map((node)=>{return node.nodeSize;} );
//     const nodeSumsSizes = cumulativeSum(nodeSizeArray);
//     editor.commands.insertContentAt(nodeSumsSizes[1],'Example Text');
//   }, [editor]);

// const swapPara = useCallback(() => {
    
//     const nodeSizeArray = editor.state.doc.content.content.map((node)=>{return node.nodeSize;} );
//     const nodeSumsSizes = cumulativeSum(nodeSizeArray);
//     const oldParagraphIndex = 1;
//     const newParagraphIndex = 4;

//     const tr = editor.state.tr; // Create a new transaction
//     // Get the content of the document
//     const content = editor.state.doc.content;

//     // Find the node you want to move
//     const nodeToMove = content.child(oldParagraphIndex);
//     const movingNodeSize = nodeSizeArray[oldParagraphIndex];
//     const newNodeLocation = newParagraphIndex < oldParagraphIndex ? nodeSumsSizes[newParagraphIndex] : nodeSumsSizes[newParagraphIndex] - movingNodeSize; 

//     //Remove the node from its current position
//     tr.delete(nodeSumsSizes[oldParagraphIndex],nodeSumsSizes[oldParagraphIndex+1]);

//     // Insert the node at the new position (e.g., at the beginning)
//     tr.insert(newNodeLocation, nodeToMove);

//     // Apply the transaction to update the document
//     let newEditorState = editor.state.apply(tr);
//     //console.log(editor);
//     editor.view.updateState(newEditorState);
//     // Now, newEditorState contains the document with the node reordered

//   }, [editor]);
  
