import React from 'react'
import PropTypes from 'prop-types'


// import ActionModal from './ActionModal';


const SmallBtn = ({buttonFunction, label, formatString, tablerIcon: TablerIcon = null }) => {



    return (
        <div className="flex items-center">
          <button type="button" onClick={buttonFunction} 
            className={`${formatString} btn-small inline-flex items-center space-x-1.5`}>
            {TablerIcon && <TablerIcon size={16} />}
            <span >{label}</span>
          </button>
        </div>
      )

}


SmallBtn.propTypes = {
    buttonFunction: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    formatString: PropTypes.string.isRequired
};

export default SmallBtn;