import {
    SCENARIO_SUCCESS,
    SCENARIO_FAIL,
    CLEAR_SCENARIO,
    SCENARIO_UPDATE_SUCCESS,
    SCENARIO_UPDATE_FAIL,
} from '../actions/types';

const initialState = {
    scenarios: null,
    scenarioSuccess: null,
    loading: true,
    saving: true,
    itemUpdated: null,
};

function scenarioReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case SCENARIO_SUCCESS:
            return {
                ...state,
                scenarios: payload,
                scenarioSuccess: true,
                loading: false,      
            }
        case SCENARIO_UPDATE_SUCCESS:
            return {
                ...state,
                itemUpdated: payload,
                saving: false,
            }
        case SCENARIO_FAIL:
            return {
                ...state,
                scenarios: null,
                scenarioSuccess: false,
                loading: false,
                     
            }
        case SCENARIO_UPDATE_FAIL:
            return{
                ...state,
                itemUpdated: null,
                saving: false,
            }
        case CLEAR_SCENARIO:
            return {
                ...state,
                scenarios: null,
                scenarioSuccess: null,
                loading: true,
                saving: true,
                itemUpdated: null,         
            }
        default:
            return state;
    }

};

export default scenarioReducer;