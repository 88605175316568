import { Extension } from '@tiptap/core'
import { v4 as uuidv4 } from 'uuid';

//There's a problem that this is all done as part of one transaction. 
// You need to change this so that it's an action on one node, and go through them in the component above.
const PopulateIds = Extension.create({
    name: 'PopulateIds',
  
    addCommands() {
        return {
          populateIDs: () => ({ tr, state }) => {
            let updated = false;

            // Go over the top-level nodes and add an id if the id is null.
            state.doc.forEach((node, pos) => {
              if (node.type.name === 'paragraph' ) { //&& !node.attrs.id: no longer checking, because it takes attributes on `Enter`.
                const id = uuidv4();
                tr.setNodeMarkup(pos, null, { ...node.attrs, id });
                updated = true;
              }
            });
    
            // Apply the transaction if there were any updates
            if (updated) {
              const newEditorState = state.apply(tr);
              return newEditorState;
            }
    
            // Return the original state if no updates were made
            return state;
          },
        emptyCommit: ()=> ({ tr, state }) => {
          
          const newEditorState = state.apply(tr);
          return newEditorState;
        }
        };
      },
  })
  
  export default PopulateIds;

        
      