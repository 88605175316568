import React, { createContext, useContext, useState, useRef, useCallback } from 'react';

// Tiptap core extensions
import { useEditor } from "@tiptap/react";
import { TextSelection } from '@tiptap/pm/state';
import Document from "@tiptap/extension-document";
// import Paragraph from "@tiptap/extension-paragraph"; //We extended paragraph in CustomParagraph
import Text from "@tiptap/extension-text";
import Link from "@tiptap/extension-link";
import Bold from "@tiptap/extension-bold";
import Underline from "@tiptap/extension-underline";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Code from "@tiptap/extension-code";
import History from "@tiptap/extension-history";

//Our customised extensions
import CustomParagraph from './TipTap/Extentions/CustomParagraph';
import AddPara from './TipTap/Extentions/AddParaExtension';
import DeletePara from './TipTap/Extentions/DeleteParaExtension';
import SwapPara from './TipTap/Extentions/SwapParaExtension';
import SourceTrail from './TipTap/Extentions/SourceTrailExtension';
import PopulateIds from './TipTap/Extentions/PopulateIDsExtention';
import CustomKeyEvents from './TipTap/Extentions/CustomCopyPaste';
import ExtractCache from './TipTap/Extentions/ExtractCacheExtension';
import ReplaceText from './TipTap/Extentions/ReplaceTextExtension';

import { cumulativeSum, ensureProtocol, findParaNum, uniqueByOrder, urlToName, appendSourceToTitle } from "./TipTap/Extentions/supportFuncs";

const EditorContext = createContext();

export const useEditorContext = () => useContext(EditorContext);

export const EditorContextProvider = ({ children }) => {
    
    // Our two lists
    const [startingList, setStartingList] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const content = "";
    
    // These are used to keep the state of the editor content up to date.
    // On unmount, we dispatch this payload to the Redux store.
      // On unmount, we dispatch this payload to the Redux store.
    const latestEditorState = useRef(null);
    const lastUpdateTimestamp = useRef(Date.now());
    const kSeconds = 1000; // 1 seconds in milliseconds
    // The purpose of this is to take care of component mount and unmount.
    // We're grabbing the state from the Redux store, and we'll set it up locally
    // once the editor is set up

    const [parsedState, setParsedState] = useState(null);

    const editor = useEditor({
        extensions: [
          Document,
          History,
          // Paragraph,
          CustomParagraph,
          Text,
          Link.configure({
            openOnClick: true
          }),
          Bold,
          Underline,
          Italic,
          Strike,
          Code,
          AddPara,
          DeletePara,
          SwapPara,
          ReplaceText,
          SourceTrail,
          PopulateIds,
          CustomKeyEvents,
          ExtractCache,

        ],
        content,
        onUpdate: ({ editor }) => {
          const currentTime = Date.now();
          
          if (currentTime - lastUpdateTimestamp.current >= kSeconds) {
            latestEditorState.current = JSON.stringify(editor.state.doc);
            lastUpdateTimestamp.current = currentTime;
          }
        },
      });
    
    //------------------------------------
    //---- LOCAL STATES OF THE EDITOR ----
    //------------------------------------

    
    //---- MODAL STATES ----
    const [range, setRange] = useState({from: null, to: null});
    const [bulkModalOpen, setBulkModalOpen] = useState(false);

    const [warningModalOpen, setWarningModalOpen] = useState({status:false, type:""});
    const [bubbleOpen, setBubbleOpen] = useState(true);       //State to manage bubble menu
    const [linkModalIsOpen, setLinkIsOpen] = useState(false);
    
    const [signalFromChunk, setSignalFromChunk] = useState({who:-1, what: null, data: null});
    const signalTypes = {alts: "ALTS", scenarios: "SCENARIOS"}

    //State to manage adding links
    const [removeSeen, setRemoveSeen] = useState(true);
    const [url, setUrl] = useState("");
    const [usedLinkList, setUsedLinkList] = useState([]);

    //-------------------
    //---- FUNCTIONS ----
    //-------------------


     //---- 1. DND FUNCTIONS ----
     
    // function that takes in index and returns collection index, snippet index - could be null, and flag
    // saying if it's the entire bookmark or snippet.

    const parseIndex = (sourceIndex) => {
        const THOUSAND = 1000;
        const HUNDRED = 100;
        const residual = sourceIndex % THOUSAND;
        const bookmarkIndex = Math.floor(sourceIndex/ THOUSAND); //this is a single bookmark
        const isSnippet = (residual === 0)? false: true
        const snippetIndex = isSnippet? residual - HUNDRED: null;

        return {isSnippet, bookmarkIndex, snippetIndex};
    };

    // This part is functions responsible for manipulating nodeList
    const deleteChunk = (chunkId) => {

        //Not entirely sure why it doesn't yell when all nodes are cleared, but 
        //will probably revisit this issue at some point.
        const nodeList = editor.state.doc.content.content;
        const dropIndex = nodeList.findIndex(node =>node.attrs.id === chunkId);

        //here we need to delete from the editor
        //We must first check if we are removing the extract from the editor extractCache
        editor.commands.deleteExtract (dropIndex);
        
        //Oncee we've taken care of ExtractCache, we can take care of deleting the paragraph.
        editor.commands.deleteParagraph(dropIndex);

    }; 

    //on Drag end
    const onDragEnd = (result) => {
        // console.log(result);
      
          if (!result.destination) return //console.log('fault is here'); 
    
          const sourceId = result.source.droppableId
          const destinationId = result.destination.droppableId
          const sourceIdName = 'start'
          
          if (destinationId === null) return
    
          if (sourceId === destinationId) {
            if (sourceId === sourceIdName) return;
            else {
              //re-arranging the order of paragraphs
              editor.commands.swapParagraph(result.source.index, result.destination.index);
            }  
          } 
          else {
            if (destinationId === sourceIdName) return
            
            // This is the meat of it - dragging from the list of bookmarks to the editor
            const sourceIndex = result.source.index;
            const destinationIndex = result.destination.index;
            const {isSnippet, bookmarkIndex, snippetIndex} = parseIndex(sourceIndex);
            const containingBookmark = startingList[bookmarkIndex];
            const sourceURL = containingBookmark.extract.url; //If no url this ends up being an empty string.
            const extractId = containingBookmark.extractPointer;
            const currentExtract = containingBookmark.extract;
    
            let text = '';
            
            //if source index is 0 mod 1000 we are dragging an entire bookmark and need the description (later: genParag)
            if (!isSnippet) {
              if (containingBookmark.extract.genParag !== "") {
                text = containingBookmark.extract.genParag;
              } else {
                text = containingBookmark.title;
              };
              
    
            // if source index is not 0 mod 0, we need to dig up the extract then snippets, take the right snippet
            // and pass along the snippet text.
            } else { 
              const snippet = containingBookmark.extract.snippets[snippetIndex];
              text = snippet.sent;
            }
    
            const source = {
              title: containingBookmark.title,
              url: sourceURL
            }
    
            //here we need to updated the editor content - on addition
            //There are two options: If it was the actual title, we want it to come with url
            // If not, just the text.
            const isTitle = (containingBookmark.title === text? true : false)
            editor.commands.addParagraph(text, source, destinationIndex, extractId, isTitle);
            
            //Here we update the list in the editor of all the urls used.
            editor.commands.addSource({
              title: containingBookmark.title,
              url: sourceURL
            });

            //Here we update the extract Object in the editor
            if(currentExtract) { //there should always be one but just in case...
              editor.commands.addExtract(currentExtract);
            }
            
          }

      };

    const bulkDraft = () => {
        if (startingList) {
        
        const { from, to } = editor.state.selection;
        setRange({from, to});
        setEditMode(true);
        setBulkModalOpen(true);
        }
        else {
          setEditMode(true);
          setWarningModalOpen({status:true, type:"choose-collection"});

        }
      }

    const closeBulkModal = () => {
          setRange({from: null, to: null});
          setBulkModalOpen(false);
        }
    
    const closeAltsModal = (data = null) => {
      setSignalFromChunk({who: -1, what: null, data: data});

    }
    //--- 2. WARNING FUNCTIONS ----------

    const closeWarningModal = () => {
      setWarningModalOpen({status:false, type:""});
    }

    //---- 3. TIP-TAP EDITOR FUNCTIONS ----
    // Toggle to edit mode
    const toggleEditMode = () => {
      if(editMode) {

          //What is the structure of the doc?
          // let nodeList1 = editor.state.doc.content.content;
          // console.log("nodelist1:",nodeList1);

          // This is in service of the drag-n-drop implementation, which requires an id/paragraph.
          // We don't care that these ids aren't fixed over time, just that they exist for dnd functionality.
          editor.commands.populateIDs();
        }
        setEditMode(!editMode);
      }

  // Functions to manage the text formatting
    const toggleBold = useCallback(() => {
      // console.log (JSON.stringify(editor.state).length);
      editor.chain().focus().toggleBold().run();
    }, [editor]);

    const toggleUnderline = useCallback(() => {
      editor.chain().focus().toggleUnderline().run();
    }, [editor]);

    const toggleItalic = useCallback(() => {
      editor.chain().focus().toggleItalic().run();
    }, [editor]);

    // Functions to manage adding links
  const dropdownListMaker = () => {
    const nodeArray = editor.state.doc.content.content;
    const nodeSizeArray = nodeArray.map((node)=>{return node.nodeSize;} );
    const position_before_paragraph_i = cumulativeSum(nodeSizeArray);

    const { selection } = editor.state;
    const { from} = selection;
  
    let linkList = nodeArray[findParaNum(from, position_before_paragraph_i)].attrs.sourceList;

    const otherLinks = editor.storage.sourceTrail.docSources
    const allLinks = uniqueByOrder([...linkList, ...otherLinks]);

    //We want to add to the title the source. title -> sourcename: title
    const totalLinks = appendSourceToTitle(allLinks);
    setUsedLinkList(totalLinks);
  }
 
  
  const openLinkModal = useCallback(() => {

    setBubbleOpen(false); //Making the bubble menu go away

    dropdownListMaker();
    editor.chain().focus();
    const currentURL = editor.getAttributes("link").href;
    if(!currentURL) {
      setRemoveSeen(false);
    }
    setUrl(currentURL? currentURL : "");
    setLinkIsOpen(true);

  }, [editor]);

  const closeLinkModal = useCallback(() => {
    setLinkIsOpen(false);
    setRemoveSeen(true);
    setUrl("");

    if (editor && editor.state && editor.view) {
      // Get the end position of the current selection
      const endPos = editor.state.selection.to;

      // Create a new transaction
      const transaction = editor.state.tr;

      // Set the selection to a cursor at the end position
      transaction.setSelection(TextSelection.create(transaction.doc, endPos));

      // Dispatch the transaction
      editor.view.dispatch(transaction);
  }

    setBubbleOpen(true);

  }, []);

  const saveLink = useCallback(() => {
    if (url) {

      const standardURL = ensureProtocol(url); //Makes sure it starts with http:// or https://

      //1. Add link to the paragraph's link list
      editor.commands.addToParaTrail({
        title: urlToName(standardURL), 
        url: standardURL
      });

      //2. Add link to the editor's link list
      editor.commands.addSource({
        title: urlToName(standardURL), 
        url: standardURL
      });

      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: standardURL, target: "_blank" })
        .run();
    } else {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    }
    closeLinkModal();
  }, [editor, url, closeLinkModal]);

  const removeLink = useCallback((url) => {
    //3. Remove link from the paragraph's link list
    editor.commands.removeFromParaTrail(url);
    
    //4. Remove link to the editor's link list. Explanation:
    // We push to the list whenever adding a link. Only during presentation we unique.
    editor.commands.removeSource(url);

    editor.chain().focus().extendMarkRange("link").unsetLink().run();
    closeLinkModal();
  }, [editor, closeLinkModal]);


    // Provide the editors and shared functionalities to children
    const value = {
        editor,
        startingList, 
        setStartingList,
        latestEditorState,
        parsedState, 
        setParsedState,

        bulkModalOpen,
        setBulkModalOpen,
        range,
        setRange,
        warningModalOpen,
        setWarningModalOpen,
        bubbleOpen, 
        setBubbleOpen,
        linkModalIsOpen, 
        setLinkIsOpen,
        removeSeen, 
        setRemoveSeen,
        url, 
        setUrl,
        usedLinkList, 
        setUsedLinkList,
        signalFromChunk, 
        setSignalFromChunk,
        signalTypes,

        parseIndex,
        onDragEnd,
        deleteChunk,
        bulkDraft,
        closeBulkModal,
        closeAltsModal,
        closeWarningModal,
        editMode, 
        setEditMode,
        toggleEditMode,
        toggleBold,
        toggleUnderline,
        toggleItalic,
        openLinkModal,
        closeLinkModal,
        saveLink,
        removeLink,

    }
    return (
        <EditorContext.Provider value={value}>
            {children}
        </EditorContext.Provider>
    );
};