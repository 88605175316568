import {
    ADD_BOOKMARK,
    REMOVE_BOOKMARK,
    GET_BOOKMARK,
    BOOKMARK_ERROR
    
    
} from '../actions/types'

const initialState = {
    savedBookmark: null,
    loadedBookmark: null,
    addloading: true,
    deleteloading: true,
    error: {}
};

function bmReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case ADD_BOOKMARK:
            return {
                ...state,
                savedBookmark: payload,
                addloading: false,

            }
        case GET_BOOKMARK:
            return {
                ...state,
                loadedBookmark: payload,
                addloading: false
                
            };
        case REMOVE_BOOKMARK:
            return {
                ...state,
                savedBookmark: null,
                addloading: true,
                deleteloading: false

            };

        case BOOKMARK_ERROR:
            return {
                ...state,
                error: payload,
                addloading: false,
                deleteloading: false
            };
        default:
            return state;
    }

};

export default bmReducer;