import {
    TRANSFORM_SUCCESS,
    TRANSFORM_FAIL,
    CLEAR_TRANSFORM,
    TRANSFORM_UPDATE_SUCCESS,
    TRANSFORM_UPDATE_FAIL,
} from '../actions/types';

const initialState = {
    paragraph: null,
    transformSuccess: null,
    loading: true,
    saving: true,
    itemUpdated: null,
};

function transformReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case TRANSFORM_SUCCESS:
            return {
                ...state,
                paragraph: payload,
                transformSuccess: true,
                loading: false,      
            }
        case TRANSFORM_UPDATE_SUCCESS:
            return {
                ...state,
                itemUpdated: payload,
                saving: false,
            }
        case TRANSFORM_FAIL:
            return {
                ...state,
                paragraph: null,
                transformSuccess: false,
                loading: false,         
            }
        case TRANSFORM_UPDATE_FAIL:
            return {
                ...state,
                itemUpdated: null,
                saving: false,         
            }        
        case CLEAR_TRANSFORM:
            return {
                ...state,
                paragraph: null,
                transformSuccess: null,
                loading: true,
                saving: true,
                itemUpdated: null,         
            }
        default:
            return state;
    }

};

export default transformReducer;