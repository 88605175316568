import React, {Fragment, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {  DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


import SingleStory from './SingleStory';

// Never ever nest component declarations!!!!
const DropComponent = ({summaryContent, deleteSnippet}) => {

  // conflict between react-beautiful-dnd and React>17. Issue documented here:
  // https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
  
    const [enabled, setEnabled] = useState(false);
  
    useEffect(() => {
      const animation = requestAnimationFrame(() => setEnabled(true));
  
      return () => {
        cancelAnimationFrame(animation);
        setEnabled(false);
      };
    }, []);
  
    if (!enabled) {
      return null;
    };
  
    //summaryContent is a list of posts, each contaning at least a sent and an id.
    
    return enabled ? (
      <Droppable droppableId="droppable">
         {(provided) => (  
            <div
              className="modal-picker-outer bg-white p-1"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >    
              {summaryContent?.map((post, index) => (
                <Draggable key={post.id} draggableId={post.id} index={index}>
                  {(provided) => (
                    <SingleStory 
                      post = {post}
                      index = {index} 
                      provided={provided} 
                      deleteSnippet = {deleteSnippet}
  
                      />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
      </Droppable>
    ):
    null;
  };

function StorySelector({summaryContent, updateSnippet, deleteSnippet}) {

    // console.log(summaryContent);
    const onDragEnd = (result) => {
      if (!result.destination) {
        // The item was dropped outside the list, do nothing
        return;
      }
    
      // Reorder the summaryContent array based on the drag and drop result
      const newSummary = Array.from(summaryContent);
      const [movedItem] = newSummary.splice(result.source.index, 1);
      newSummary.splice(result.destination.index, 0, movedItem);
    
      // Update the state or any other relevant logic
      // Here, we'll assume you have a function to update the state with the new order of summaryContent.
      // You might need to pass this function as a prop from the parent component.
      updateSnippet(newSummary);
    };
  
    
    return (
      <Fragment>
        <DragDropContext onDragEnd={onDragEnd}>
          <DropComponent summaryContent={summaryContent} deleteSnippet = {deleteSnippet}/>
        </DragDropContext>
      </Fragment>
    
    )
  }



StorySelector.propTypes = {
  summaryContent: PropTypes.array,
  updateSnippet: PropTypes.func.isRequired
}

export default StorySelector