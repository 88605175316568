import React, {Fragment, useState, useEffect} from 'react';


import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRecentCollections, saveCollection, updateCollection } from '../../../actions/collection';
import { setAlert } from '../../../actions/alert';
import NewCollectionModal from './NewCollectionModal';
import * as Icons from "../../Editor/TipTap/WorkingExample/Icons";
import { LinkM } from '../../layout/Icons';

//To create or add to a collection we need: collection title, snippets, url. We also need for a new collection its name and description

function CollectionAdd({urlTitle, description, snippets, url, getRecentCollections, saveCollection, updateCollection, setAlert, collection}) {

    // Clicking state
    const [menu, setMenu] = useState({
        addButtonClicked: false,
        newButtonClicked: false
    });
    const {addButtonClicked, newButtonClicked } = menu;
    const maxCollectionCount = 10;

    //Menu items state
    const [recentCollections, setRecentCollections] = useState([]);

    //New collection state
    // const [newCollection, setNewCollection] = useState ({
    //     newCollectionTitle:''
    // });
    // const {newCollectionTitle} = newCollection;
    
    const [selectedCollections, setSelectedCollections] = useState([]);
   

    // This comes from the store
    const {allCollections} =  collection

    useEffect(() => {
        let collectionList = allCollections;
        if (collectionList.length > maxCollectionCount) {
          setRecentCollections(collectionList.slice(0,maxCollectionCount));
        }
        else{
          setRecentCollections(collectionList);
        }
        
      }, [allCollections]);
    
    const toggleCollectionsMenu = (e) => {
        e.preventDefault();
        getRecentCollections(maxCollectionCount);
        setMenu({addButtonClicked: !addButtonClicked, newButtonClicked: false})
    //     if(!addButtonClicked){
    //         // await getRecentCollections(5);
    //         setMenu({addButtonClicked: !addButtonClicked, newButtonClicked: false})
    //     } else {
    //     setMenu({newButtonClicked:false, addButtonClicked: !addButtonClicked})
    // }
      };

    const toggleAddMenu = () => {
        setMenu({addButtonClicked: false, newButtonClicked: !newButtonClicked})
      };
    

    

    const handleCheckboxChange = (e) => {
        const collectionId = e.target.value;
        const collectionTitle = e.target.name;

        if (e.target.checked) {
        // Add to selected collections
            setSelectedCollections((prevSelected) => [
            ...prevSelected,
            { id: collectionId, title: collectionTitle },
          ]);
        } else {
        // Remove from selected collections
        setSelectedCollections((prevSelected) =>
        prevSelected.filter((collection) => collection.id !== collectionId));
        }
    };

    const saveToCollections = async () => {
        if (selectedCollections.length === 0) {
          setAlert('Please select at least one collection.', 'danger');
          return;
        }
    
        // Save the bookmarks to selected collections
        for (const selectedCollection of selectedCollections) {
          const collectionData = {
            id: selectedCollection.id,
            title: urlTitle, 
            url,
            snippets,
            description
          };
    
          updateCollection(collectionData);
        }
    
        // setAlert('Saved to selected collections!', 'success');
        setMenu({ addButtonClicked: false, newButtonClicked: false });
      };

    const addToNew = async (newCollectionTitle) => {
        // e.preventDefault();

        const collectionData = {
            collectionTitle: newCollectionTitle,
                urlTitle,
                url,
                snippets,
                description,
        };


        // Create new collection
        await saveCollection(collectionData);
        setMenu({addButtonClicked: false, newButtonClicked:false});
        
    }

    return (
        <Fragment>
            <button onClick={toggleCollectionsMenu} title = "Add to collection" className="btn btn-light md:w-1/2">
                <i className="fa fa-suitcase text-primary"></i><span className="m">Add to collection</span>
            </button>
        
            {addButtonClicked && 

            <div className="relative z-10">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">     
            <div className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">

                <LinkM content = "Add to Collection" />
                <button className="modal-close" type="button" onClick={toggleCollectionsMenu}>
                    <Icons.X />
                </button>

                <div className="mt-4 mb-1"><strong>Recent collections:</strong></div>
                <div>
                    {recentCollections?.map((collection, index) => (
                        <div key={index}>
                        <input 
                            type="checkbox"  
                            id = {collection._id} 
                            name= {collection.title} 
                            value={collection._id}
                            onChange={handleCheckboxChange}
                            checked={selectedCollections.some(c => c.id === collection._id)}
                            />
                        <label for={collection._id}> {collection.title}</label>
                        </div>
                        
                    ))}
                </div>
            </div> 

                    <div className="bg-slate-100 px-4 py-1 sm:flex sm:flex-row-reverse sm:px-6">
                    <div className="modal-buttons my">
                    {recentCollections.length>0&& (<button className="btn btn-secondary my" onClick={saveToCollections}>
                    Save to selected
                    </button> )}
                    <button className = "btn my" onClick={toggleAddMenu}>Create new collection</button>
                    </div>
                    
        </div>        
        </div>
        </div>
        </div>
        </div>

        }

        {newButtonClicked && 

         <NewCollectionModal 
                    onAccept = {addToNew}
                    onCancel = {()=> {setMenu({
                        addButtonClicked: false,
                        newButtonClicked: false
                    })}}
                    blurb = "Pick a name for the new collection"
                    yesButtonText='Create Collection and Save'
                    modalTitle='Save to New Collection'
                    />
                    }
        </Fragment>
    )
}
   

const mapStateToProps = state => ({
    collection: state.collection
  });

CollectionAdd.propTypes = {
    urlTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    snippets: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
    getRecentCollections: PropTypes.func.isRequired,
    saveCollection:PropTypes.func.isRequired,
    updateCollection: PropTypes.func.isRequired,
    setAlert:PropTypes.func.isRequired,
    collection:PropTypes.object.isRequired,

  }

export default connect(mapStateToProps,{getRecentCollections, saveCollection, updateCollection, setAlert})(CollectionAdd);

