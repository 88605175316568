import React from 'react';
import PropTypes from 'prop-types';

const Dropdown = ({ items, selectedItem, onItemChange, label="" }) => {
  return (
    <div className="collection-dropdown">
      {label && <label className="dropdown-label">{label}</label>}
        <select
          id="collectionSelect"
          className='dropdown-item'
          name="collection"
          value={selectedItem}
          onChange={(e) => onItemChange(e.target.value)}
        >
          <option value="">Select...</option>
          {items.map((item) => (
            <option key={item._id} value={item._id}>
              {item.title}
            </option>
          ))}
        </select>

    </div>
  );
};

Dropdown.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.string.isRequired,
  onItemChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default Dropdown;