import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import useBackground from '../../layout/utils/useBackground';
import SummaryBlock from './SummaryBlock'; // manages order and deletion 
import Reader from './Reader'; // Displays the article and does the highlighting
import CollectionAdd from './CollectionAdd';
import { Eye } from '../../layout/Icons';

const uuid = require('uuid');


//Redux devtools is going to look funny because of beatiful drag and drop.
// See here: https://github.com/atlassian/react-beautiful-dnd/issues/1437

const Clipper = ({fetchObj, gistObj }) => {

  useBackground('#f1f5f9', '#f1f5f9');
  // Set the data state
  const [mode, setMode] = useState({
    formatText: "",
    summarySents: [],
    url: "",
    title: "",
    description: ""
  });

  const {formatText, summarySents, url, title, description} = mode;
  
  
  // The summary displayed will be this local state.
  const [snippets, setSnippets] = useState([]);
  const [titleSnippet, setTitleSnippet] = useState({});

  const getStates = useCallback(() => {
    return {
      fetchContent: fetchObj.content? fetchObj.content : null,
      gistContent: gistObj.content? gistObj.content : null
    }

  }, [fetchObj, gistObj]);
  
  // Are we here because we fetched or because we gisted?
  // fill in the content based on the answer
  // We navigate here only after the state has been set elsewhere...
  useEffect(() => {

    const {fetchContent, gistContent} = getStates();

    setMode(fetchContent? 
      {
        formatText: fetchContent? fetchContent.formatted_article_text:"",
        summarySents: fetchContent? fetchContent.summary_sents:[],
        url: fetchContent? fetchContent.url:"",
        title: fetchContent? fetchContent.title:"",
        description: fetchContent? fetchContent.description:""

      } : {
        formatText: gistContent? gistContent.formatted_article_text:"",
        summarySents: gistContent? gistContent.summary_sents:[],
        url: gistContent? gistContent.url:"",
        title: gistContent? gistContent.title:"",
        description: gistContent? gistContent.description:""
      });



  },[getStates]); 


  // Once it's clear who the summarySents are, we update them.
  useEffect(() =>{
    setTitleSnippet(summarySents[0]);
    setSnippets(summarySents.slice(1));
  },[summarySents]);

  //Highlightng
  const [highlightedText, setHighlightedText] = useState('');

  const handleHighlight = () => {
      const selectedText = window.getSelection().toString();
      setHighlightedText(selectedText);
    };

  const handleAddSnippet = () => {
      // Logic to add a new sentence to the sentences array
      const newSnippet = { id: uuid.v4(), sent: highlightedText };
      addSnippet(newSnippet);
    };

  // Functions to manipulate the summary
  const addSnippet = (newSnippet) => {
    setSnippets((prevSnippets) => [newSnippet, ...prevSnippets]);
  };

  const deleteSnippet = (snippetId) => {
    setSnippets((prevSnippets) => prevSnippets.filter((snippet) => snippet.id !== snippetId));
  };

  const updateSnippetOrder = (newSnippets) => {
    setSnippets(newSnippets);
  };
 

  // Can't be here unless you've done something prior
  if (!fetchObj.content && !gistObj.content) {
    return <Navigate to="/fetch" />;
  };


  return (
    <Fragment>
      <div className="bg-white surround p-1 my-1 rounded-md shadow-sm">
        <Eye content={"Controls"} />
          <div className="summary ">
          <div className="flex justify-end items-end"> 
          {/* space-x-10 */}
              
                <div className="border-r border-gray-300 pr-10">
                    <div className="flex flex-col justify-end">
                      <button onClick = {handleAddSnippet} className="btn btn-light ">
                      <div className='flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M12 1.5a.75.75 0 01.75.75V4.5a.75.75 0 01-1.5 0V2.25A.75.75 0 0112 1.5zM5.636 4.136a.75.75 0 011.06 0l1.592 1.591a.75.75 0 01-1.061 1.06l-1.591-1.59a.75.75 0 010-1.061zm12.728 0a.75.75 0 010 1.06l-1.591 1.592a.75.75 0 01-1.06-1.061l1.59-1.591a.75.75 0 011.061 0zm-6.816 4.496a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.68zM3 10.5a.75.75 0 01.75-.75H6a.75.75 0 010 1.5H3.75A.75.75 0 013 10.5zm14.25 0a.75.75 0 01.75-.75h2.25a.75.75 0 010 1.5H18a.75.75 0 01-.75-.75zm-8.962 3.712a.75.75 0 010 1.061l-1.591 1.591a.75.75 0 11-1.061-1.06l1.591-1.592a.75.75 0 011.06 0z" clipRule="evenodd" />
                        </svg> 
                        <span className='ml-2'>Add Highlight</span>
                        </div>
                      </button>
                    </div> 
                </div>
              </div>
            
         
            <div className="flex flex-col justify-end">
            <CollectionAdd urlTitle = {title} description = {description} snippets = {[titleSnippet,...snippets]} url = {url}/>
            </div>
          </div>
      </div>
      
      <div className="summary my-1">
        <div>
          <Reader formattedText = {formatText}  handleHighlight={handleHighlight}/>
        </div>
        <div>
            {snippets && 
              <SummaryBlock summaryContent= {snippets} updateSnippet = {updateSnippetOrder} deleteSnippet = {deleteSnippet}
            />}

            <div className="dash-buttons my-1">
          
          </div>
        </div>
      </div>
    </Fragment>

)
};


const mapStateToProps = state => ({
  fetchObj: state.fetch,
  gistObj: state.gist,
});


Clipper.propTypes = {
fetchObj: PropTypes.object.isRequired,
gistObj: PropTypes.object.isRequired
};


export default connect(mapStateToProps,{})(Clipper);
