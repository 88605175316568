import { Extension } from '@tiptap/core'
import { cumulativeSum, findParaNum } from './supportFuncs'

// This is an example of how you'll be able to store a list of urls

const SourceTrail = Extension.create({
  name: 'sourceTrail',

  addStorage() {
    return {
      docSources: [],
      tempSources: [],
    }
  },

  addCommands() {
    return {
        addSource: (source) => ({ tr, state, editor }) => {
            //source: { title, url}
            
            // Source addition logic here
            editor.storage.sourceTrail.docSources.push(source);

            // Apply the transaction to update the document
            let newEditorState = state.apply(tr);

            // Return the updated editor state
            return newEditorState;
          },
          addSources: (sources) => ({ tr, state, editor }) => {
            // Ensure 'sources' is an array
            if (!Array.isArray(sources)) {
                console.error('Sources should be an array');
                return state.tr; // Return the unchanged transaction if 'sources' is not an array
            }

            // Iterate over the array of sources and add each one
            sources.forEach(source => {
                // Check if the source has the required structure { title, url }
                if (source && source.title && source.url) {
                    editor.storage.sourceTrail.docSources.push(source);
                } else {
                    console.error('Source is missing title or url:', source);
                }
            });

            // Apply the transaction to update the document
            let newEditorState = state.apply(tr);

            // Return the updated editor state
            return newEditorState;
        },        
        removeSource: (urlToRemove) => ({tr, state, editor}) => {
            const { docSources } = editor.storage.sourceTrail;

            // Find the index of the source with the matching URL
            const indexToRemove = docSources.findIndex((source) => source.url === urlToRemove);
        
            if (indexToRemove !== -1) {
              // Remove the source from the list (only the first occurrence)
              docSources.splice(indexToRemove, 1);
          
              // Apply the transaction to update the document
              let newEditorState = state.apply(tr);
          
              // Return the updated editor state
              return newEditorState;
          }
        },
        addTempSources: (sourceList) => ({ tr, state, editor  }) => {
          //source: { title, url}

          // Source addition logic here
          editor.storage.sourceTrail.tempSources = sourceList;       

          // Apply the transaction to update the document
          let newEditorState = state.apply(tr);

          // Return the updated editor state
          return newEditorState;
        },
        clearTempSources: () => ({ tr, state, editor }) => {
          editor.storage.sourceTrail.tempSources = [];

          // Apply the transaction to update the document
          let newEditorState = state.apply(tr);

          // Return the updated editor state
          return newEditorState;
        },
        clearDocSources: () => ({ tr, state, editor }) => {
          editor.storage.sourceTrail.docSources = [];

          // Apply the transaction to update the document
          let newEditorState = state.apply(tr);

          // Return the updated editor state
          return newEditorState;
        },
        addToParaTrail: (source) => ({tr, state, editor}) => {
          const nodeArray = editor.state.doc.content.content;
          const nodeSizeArray = nodeArray.map((node)=>{return node.nodeSize;} );
          const position_before_paragraph_i = cumulativeSum(nodeSizeArray);

            //1. Find the paragraph into which the text has been inserted
          const { selection } = editor.state;
          const pastePosition = selection.from;
          const targetPara = findParaNum(pastePosition, position_before_paragraph_i);

          nodeArray[targetPara].attrs.sourceList.push(source);
          // Apply the transaction to update the document
          let newEditorState = state.apply(tr);

          // Return the updated editor state
          return newEditorState;
          
        },
        removeFromParaTrail: (urlToRemove) => ({tr, state, editor}) => {
          const nodeArray = editor.state.doc.content.content;
          const nodeSizeArray = nodeArray.map((node)=>{return node.nodeSize;} );
          const position_before_paragraph_i = cumulativeSum(nodeSizeArray);

           //1. Find the paragraph into which the text has been inserted
          const { selection } = editor.state;
          const pastePosition = selection.from;
          const targetPara = findParaNum(pastePosition, position_before_paragraph_i);

          const paraTrail = nodeArray[targetPara].attrs.sourceList;
          // Find the index of the source with the matching URL
          const indexToRemove = paraTrail.findIndex((source) => source.url === urlToRemove);
      
          if (indexToRemove !== -1) {
            // Remove the source from the list (only the first occurrence)
            paraTrail.splice(indexToRemove, 1);
        
            // Apply the transaction to update the document
            let newEditorState = state.apply(tr);
        
            // Return the updated editor state
            return newEditorState;
        }
      },
    }},
})

export default SourceTrail