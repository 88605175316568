import React from 'react';
import PropTypes from 'prop-types';

const LinkDropdown = ({ items, selectedItem, onItemChange, label }) => {
  return (
    <div className="collection-dropdown py">
      <label className="dropdown-label">{label}</label>
        <select
          id="collectionSelect"
          className='dropdown-item'
          name="collection"
          value={selectedItem.url}
          onChange={(e) =>  {
            onItemChange(e.target.value);
          }}
        >
          <option value="">Select...</option>
          {items.map((item, index) => (
            <option key={index} value={item.url}>
              {item.title}
            </option>
          ))}
        </select>

    </div>
  );
};

LinkDropdown.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.object.isRequired,
  onItemChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default LinkDropdown;