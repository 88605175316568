import React, { Fragment, useState, useEffect } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import cloneDeep from 'lodash/cloneDeep';

import PropTypes from 'prop-types';

import { useEditorContext } from './EditorContextProvider';
import TextChunk from './TextChunk';
import ShowAltsModal from './TipTap/WorkingExample/Modals/AltsModal/ShowAltsModal';
import ScenarioModal from './TipTap/WorkingExample/Modals/ScenarioModal/ScenarioModal';

/* eslint-disable react-hooks/exhaustive-deps */

const DraftSpace = ({dropId }) => {
  const { 
    editor,
    parsedState,
    setParsedState,
    signalFromChunk,
    signalTypes, 
    } = useEditorContext();

    // conflict between react-beautiful-dnd and React>17. Issue documented here:
    // https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
    
      const [enabled, setEnabled] = useState(false);
      const [altsModalExtract, setAltsModalExtract] = useState(null);
      const [scenarioModalData, setScenarioModalData] = useState(null);
      const nodeList = editor.state.doc.content.content;

      // Generate the boolean array in a single loop
      const yesGenParag = nodeList.map(node => {
        const extractId = node.attrs.extractId; // Extract the extractId
        
        const extract = extractId ? editor.storage.extractCache.extractObj[extractId] : null; // Get the extract object
        const nodeText = node.textContent; // Get the text content of the node

        // console.log ({ genParag: extract ? extract.genParag : "" });
        
        // Check if extract has genParag and if nodeText contains it AND whether there already exist alernatives.
        // && extract.altParags[0]
        if (extract && extract.genParag ) {

          const startIndex = nodeText.indexOf(extract.genParag);
          if (startIndex !== -1) {
            return {
              contains: true,
              from: startIndex,
              to: startIndex + extract.genParag.length,
              genParag: extract.genParag,
              altsList: extract.altParags,
              extractId: extractId
            };
           }
          } 
        return {
          contains: false,
          from: null,
          to: null,
          genParag: "",
          altsList: [""],
          extractId: extractId
        }; // If genParag is not present
        
      });
      
      // const textList = editor.state.doc.content.content.map((node)=>{return node.textContent});
      // const idList = editor.state.doc.content.content.map((node)=>{return node.attrs.id});
      // console.log(idList);
  
      useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));
    
        return () => {
          cancelAnimationFrame(animation);
          setEnabled(false);
        };
      }, []);


    // IF SOMETHING IS SAVED, THERE ARE TWO POSSIBILITIES:
    // ONE, we just came back from a different place, and we're reloading what we saved earlier.
          
    // TWO, we had a draft, cleared it, and because of it have this residual state that got saved:
    // When we cleared the state of the editor, the Panels component had to remount, because the state changed
    // For that, it had to unmount first. So we get an extra update to the redux state, of an emty draft.
    // So we just need too make sure we load the draft from the state if it's not empty.
    
    useEffect(() => {
    
    if (parsedState) { 

      // editor.commands.setContent(parsedState.content, false);
      // editor.commands.populateIDs();
      // setParsedState(null);
      
      const realDraftSignal = parsedState.content[0].hasOwnProperty('content');

      if (realDraftSignal) {
      editor.commands.setContent(parsedState.content, false);
      editor.commands.populateIDs();
      setParsedState(null);
      }
    }
    
     }, []);

     useEffect(() => {
    
      if (signalFromChunk.who !== -1) { 
        // we are adding in the self-reported index from the Chunk, so we know which paragraph to change
        if (signalFromChunk.what === signalTypes.alts) {
          setAltsModalExtract({...yesGenParag[signalFromChunk.who], paraIndex:signalFromChunk.who});
        }
        if (signalFromChunk.what === signalTypes.scenarios) {
          
          // What scenarios have we got?
          const currentNode = editor.state.doc.content.content[signalFromChunk.who];
          const currentExtractId = currentNode.attrs.extractId;
          const currentExtract = currentExtractId ? editor.storage.extractCache.extractObj[currentExtractId] : null;
          const scenarioObj = currentExtract? currentExtract.scenarios : null; 

          setScenarioModalData({scenarioObj:scenarioObj, paraIndex: signalFromChunk.who})
        }
        } else { // this means the signal is -1. 

          // But if the data field is not null, it means we got sent data.
          // All we need from it is the extractId, the type of field, and the data.
          // Based on that, we can update the editorCache
          const data = signalFromChunk.data;
          if(data && data.packet) { // we sent back something
            // But it only matters if there was a non-null packet. If there were no alts or scenarios and we
            // Decided not to generate any, the packet would be null and there's nothing to do.
            if (data.fieldType === signalTypes.alts) {
              const clonedAltsArray = [...signalFromChunk.data.packet]; // Clone the array here just in case
              editor.commands.updateExtractAlts(clonedAltsArray, data.extractId);
            }
            if (data.fieldType === signalTypes.scenarios) {
              const clonedScenarioObj = cloneDeep(signalFromChunk.data.packet); // Clone the array here just in case
              editor.commands.updateExtractScenarios(clonedScenarioObj, data.extractId);
            }
          }
          setAltsModalExtract(null);
          setScenarioModalData(null);
        }
       }, [signalFromChunk]);
  
     //eslint-disable-next-line
      // Add an event handler to capture the drag-and-drop operation result
    
      return enabled ? (
        <Fragment>
        <Droppable droppableId={dropId}>
           {(provided) => (
              <div
                className="inner-panel bg-white p-1" // used to be summary-block
                {...provided.droppableProps}
                ref={provided.innerRef}
              > 
              
                {nodeList?.map((node, index) => (
                  <Draggable key={node.attrs.id} draggableId={node.attrs.id} index={index}>
                    {(provided) => (
                      <TextChunk 
                        content = {node.textContent? node.textContent:""} // instead of item.text: textList[index]
                        provided={provided} 
                        id={node.attrs.id}
                        containsGenParag = {yesGenParag[index].contains}
                        index = {index}
                        extractOrigin = {yesGenParag[index].extractId?true:false}     
                        />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
        </Droppable>
        {altsModalExtract && 
          <ShowAltsModal 
            containsGenParagObj = {altsModalExtract}
          />
        }
        {scenarioModalData &&
          <ScenarioModal dataObj = {scenarioModalData}
          />
        }
        </Fragment>
      ):
      null;
    };


DraftSpace.propTypes = {
  dropId: PropTypes.string.isRequired
};
      

export default DraftSpace;

