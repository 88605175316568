import React, { useState } from 'react'
import { Navigate } from 'react-router-dom';
// import axios from 'axios'

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth'
import loginImage from './../../img/seatedA.png';

const Login2 = ({login, isAuthenticated}) => {



  const [formData, setFormData] = useState({
    email: "",
    password: "",
    });

  const {email, password} = formData;

  const onChange = e => {
    setFormData ({...formData, [e.target.name]:e.target.value});
  }

  const onSubmit =  (e) => {
    e.preventDefault();
    login(email, password); 
    }


    //Redirect if logged in
  if(isAuthenticated){
    return <Navigate to="/fetch" />;
    } 

  return (
    <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
            <div className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"}}></div>
        </div>
        <div className="justify-center mx-auto max-w-7xl px-6 pb-10 pt-10 sm:pb-32 lg:flex lg:px-8 lg:pt-10">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-4 md:w-1/2">
            
                <div className="flex min-h-full flex-col justify-top py-10 ">


                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="bg-white px-4 sm:p-6 surround form rounded-md shadow-md" onSubmit={onSubmit}>
                        <div className="sm: mb-4 sm:text-left">
                        <h1 className="text-xl font-semibold leading-6 text-gray-900">Welcome back!</h1>
                        </div>
                        <div className='mb-4'>
                            <label  className="block font-quiet">Email address</label>
                            <div className="mt-1 ">
                            <input 
                                id="email" 
                                name="email"
                                placeholder="Email Address" 
                                type="email"
                                value = {email}
                                onChange={e=>onChange(e)} 
                                required 
                                className="block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                            </div>
                        </div>

                        <div className='mb-4'>
                            <div className="flex items-center justify-between">
                            <label className="block font-quiet">Password</label>
                            </div>
                            <div className="mt-1 ">
                            <input 
                                id="password" 
                                name="password"
                                placeholder="Password" 
                                type="password"
                                value = {password}
                                onChange={e=>onChange(e)} 
                                required 
                                className=" block w-full border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6 "/>
                            </div>
                        </div>

                        <div className='mt-6 mb-2'>
                            <button 
                                type="submit" 
                                className="flex w-full justify-center bg-purple-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600">Sign in</button>
                        </div>
                        </form>

                        {/* <p className="mt-10 text-center text-sm text-gray-500">
                        Not a member?
                        <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Start a 14 day free trial</a>
                        </p> */}
                    </div>
                    </div>


            
            </div>
            <div className='w-1/2'>
                <img src = {loginImage} width = "1680" height = "1425" alt="Female superhero flying amidst a blast of paper"/>
            </div>
            {/* <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style={{clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"}}></div>
            </div> */}
        </div>
</div>


    //   <Fragment>
        
    //     <h1 className="large text-primary bg-green-400">Sign In</h1>
    //     <p className="lead btn_test_tw"><i className="fas fa-user"></i> Sign into your account</p>
    //     <form className="form" onSubmit={onSubmit}>
    //       <div className="form-group">
    //         <input
    //           type="email"
    //           placeholder="Email Address"
    //           name="email"
    //           value = {email}
    //           onChange={e=>onChange(e)}
    //           required
    //         />
    //       </div>
    //       <div className="form-group">
    //         <input
    //           type="password"
    //           placeholder="Password"
    //           name="password"
    //           value = {password}
    //           onChange={e=>onChange(e)}
    //           required
    //         />
    //       </div>
    //       <input type="submit" className="btn btn-primary" value="Login" />
    //     </form>
    //     {/* <p className="my-1">
    //       Don't have an account? <Link to="/register">Sign Up</Link>
    //     </p> */}
        
    // </Fragment>
    
  )
    
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

Login2.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
}

// Note you're goign to get an error in the console over isAuthenticated.
// It starts off as null, and only once it fails/succeeds to load the user it
// becomes a boolean. PropTypes doesn't really support saying it should be either this or that.
// The given advice is to drop .isRequired, but it's a lot easier to pick up bugs with it so...

export default connect(mapStateToProps,{ login })(Login2);