import { Extension } from '@tiptap/core';
import { cumulativeSum, findParaNum, uniqueByOrder } from './supportFuncs';

// Logic here is wonky and messes up when copying and pasting more than a paragraph.
// Need to make it more robust

const CustomKeyEvents = Extension.create({
  name: 'customKeyEvents',

  addKeyboardShortcuts() {
    return {
      'Mod-c': ({ editor }) => {
        // console.log('Copied!');
        const { selection } = editor.state;
        const { from, to } = selection;

        const nodeArray = editor.state.doc.content.content;
        const nodeSizeArray = nodeArray.map((node)=>{return node.nodeSize;} );
        const position_before_paragraph_i = cumulativeSum(nodeSizeArray);

        // console.log(`Selection from ${from} to ${to}`);

        //1. Find which paragraphs the selection belongs in
        const startingPara = findParaNum(from, position_before_paragraph_i);
        const endingPara = findParaNum(to, position_before_paragraph_i);

        // console.log(`Selection started at paragraph ${startingPara}`);
        // console.log(`Selection ended at paragraph ${endingPara}`);
        
        //2. Access those paragraphs sourceList & add all those sources into one list
        let copiedSourceList = []
        for (let i=startingPara; i<endingPara+1;i++){
            const nodeSources = nodeArray[i].attrs.sourceList;
            copiedSourceList = [...copiedSourceList, ...nodeSources]
        }

        //3. call the editor command addTempSources
        editor.commands.addTempSources(copiedSourceList);

        // Continue with default copy behavior
        return false; // Return false to let the editor handle the copying
      },
      'Mod-x': ({ editor }) => {
        // console.log('Cut!');
        const { selection } = editor.state;
        const { from, to } = selection;

        const nodeArray = editor.state.doc.content.content;
        const nodeSizeArray = nodeArray.map((node)=>{return node.nodeSize;} );
        const position_before_paragraph_i = cumulativeSum(nodeSizeArray);

        //1. Find which paragraphs the selection belongs in
        const startingPara = findParaNum(from, position_before_paragraph_i);
        const endingPara = findParaNum(to, position_before_paragraph_i);

        
        //2. Access those paragraphs sourceList & add all those sources into one list
        let copiedSourceList = []
        for (let i=startingPara; i<endingPara+1;i++){
            const nodeSources = nodeArray[i].attrs.sourceList;
            copiedSourceList = [...copiedSourceList, ...nodeSources]
        }

        //3. call the editor command addTempSources
        editor.commands.addTempSources(copiedSourceList);

        // Continue with default copy behavior
        return false; // Return false to let the editor handle the copying
      },
      'Mod-v': ({ editor }) => {
        // console.log('Pasted!');

        const nodeArray = editor.state.doc.content.content;
        const nodeSizeArray = nodeArray.map((node)=>{return node.nodeSize;} );
        const position_before_paragraph_i = cumulativeSum(nodeSizeArray);

        //1. Find the paragraph into which the text has been inserted
        const { selection } = editor.state;
        const pastePosition = selection.from;
        const targetPara = findParaNum(pastePosition, position_before_paragraph_i);

        //2. Grab the saved list and append it to the existing list
        const nodeSources = nodeArray[targetPara].attrs.sourceList;
        const savedSources = editor.storage.sourceTrail.tempSources;

        //3. Unique it based on the url
        nodeArray[targetPara].attrs.sourceList = uniqueByOrder([...nodeSources, ...savedSources]);
        
        //4. Clear the temp state in the editor.
        editor.commands.clearTempSources();

        return false; // Return false to let the editor handle the pasting
      },
    };
  },
});

export default CustomKeyEvents