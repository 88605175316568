import React, {Fragment, useEffect} from 'react'
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {useEditorContext} from '../../Editor/EditorContextProvider';

// Components
import CollectionSpace from '../../Editor/CollectionSpace';
import DraftSpace from '../../Editor/DraftSpace';
import EditorArea from '../../Editor/TipTap/WorkingExample/EditorArea'

//Actions
import { updateLocalDraft } from '../../../actions/draft';

//@TODO: 
// Start by just bringing in the collections section, see where we are with the functionality and CSS


const Panels = ({
    isRightPanelVisible,
    isAuthenticated,
    localDraft, 
    updateLocalDraft, 
}) => {
    const { 
        editor,
        startingList, 
        latestEditorState,
        parsedState, 
        setParsedState,
        onDragEnd,
        deleteChunk,
        editMode, 
        } = useEditorContext();
    
    // The purpose of this is to take care of component mount and unmount.
    // We're grabbing the state from the Redux store, and we'll set it up locally
    // once the editor is set up
    const {editorState, editorLoading} = localDraft;

    useEffect(() => {

        if(!editorLoading && editorState) { //this is the redux state. Checking something is saved
          try {
            setParsedState(JSON.parse(editorState)); //We are saving whatever is saved. We check it in DraftSpace.
          } catch (error) {
            console.error('Error parsing editor state:', error);
          }
        }

        return () => {
            const currentState = latestEditorState.current;
            if(currentState && isAuthenticated){ //We don't need to save the draft if we're logged out.
              // console.log(isAuthenticated);
              updateLocalDraft(currentState);
            }
          
          };// eslint-disable-next-line react-hooks/exhaustive-deps
        },[editorLoading, editorState, isAuthenticated]);


    return (
        <Fragment>
        <div className={`panel-container ${isRightPanelVisible ? 'with-right' : 'without-right'}`}>
            <DragDropContext  onDragEnd={onDragEnd} > 


            <div className={`left-panel s-border ${!isRightPanelVisible ? 'centered' : ''}`}>
                {/* Content for the left panel */}
                {editor && !editMode &&
                    <DraftSpace
                        dropId = 'destination'
                        // deleteChunk = {deleteChunk}
                        // editor = {editor}
                        // parsedState = {parsedState}
                        // setParsedState={setParsedState}
                        
                        />  }
                {editor && editMode && 
                    <EditorArea />
                }
            </div>
            {isRightPanelVisible && (
                <div className="right-panel s-border">
                    {/* Right panel content */}
                    {startingList && 
                      <CollectionSpace
                        dropId = 'start'
                        collectionContent={startingList} 
                       
                        />  }
                    {!startingList &&
                      <div className='bg-white p-1'></div>
                    }
                </div>
            )}


            </DragDropContext>  
        </div>
    </Fragment>
    )
    }

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    localDraft: state.draft
    });

Panels.propTypes = {
    isRightPanelVisible: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    localDraft: PropTypes.object.isRequired,
    updateLocalDraft: PropTypes.func.isRequired,
}

export default connect(mapStateToProps,{updateLocalDraft})(Panels);