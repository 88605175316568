import { Paragraph } from '@tiptap/extension-paragraph'

const CustomParagraph = Paragraph.extend({
    
    addAttributes() {
            return {
        ...this.parent?.(),
        sourceList: {
            default: [],
            },
        id: {
            default: null, // Initially null, will be set on node creation
        },
        extractId: {
            default: null, // Initially null, will be set on DragEnd or inherited
        },
    }
    },

    addStorageMethods() {
        return {
        appendSource: (source) => {
            const currentList = this.attrs.sourceList;
            const updatedList = [...currentList, source];
            // Update the attribute directly or through a transaction
            // This part might vary depending on how you access and update node attributes in your setup
            this.updateAttributes({ sourceList: updatedList });
        }

        }
}
})

export default CustomParagraph