import React, { useState, } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { removeBookmark } from '../../../actions/collection';
import Popup from '../../layout/Popup';
// import { set } from 'mongoose';

const BmPreview = ({title, description, savedString, bookmarkId, collectionId, removeBookmark}) => {
 
  const [seeDelete, setSeeDelete] = useState(false);
  const [clicked,setClicked] = useState(false)


  const updateTools = () => {
    setSeeDelete(!seeDelete);
  }

  const open = () => {
    // console.log('delete!');
    setClicked(true);

  };

  // const handleDeleteBookmark = () => {
  //   yesRemoveBookmark();
  // }

  const yesRemoveBookmark = ()=> {
    removeBookmark(collectionId, bookmarkId);
    // console.log('removed!');
  }

  const close = () => {
    setClicked(false);
    setSeeDelete(false);
  };



  return (

        <div className="flex"
        onMouseEnter={updateTools}
        onMouseLeave={updateTools}
        >
          
            {clicked && <Popup 
                modalTitle='Delete Bookmark'
                onAccept={yesRemoveBookmark}
                onCancel = {close}
                blurb = 'Delete this bookmark? Data will be permanently removed. This action cannot be undone.'
                yesButtonText='Delete'
            />}
            <div className="w-full summary-sent m p"> 
                <p><strong>{title}</strong></p> 
                <p>{description}</p>
                <p className="font-quiet">Saved: {savedString}</p>
            </div>

        {seeDelete && 
            <div className='w-1/10 tools'>
              <button onClick = {open} className='tool-btn'><i className="fa-solid fa-trash-can"></i></button>
            </div>
          }

    </div>
    );

}


const mapStateToProps = state => ({

  });
  
  
BmPreview.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  savedString: PropTypes.string.isRequired,
  bookmarkId: PropTypes.string.isRequired,
  collectionId: PropTypes.string.isRequired,
  removeBookmark: PropTypes.func.isRequired,
 
 
    
  };
  
  
  export default connect(mapStateToProps,{removeBookmark})(BmPreview);