import React, {useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import * as Icons from "../Icons";

import { TextSelection } from '@tiptap/pm/state';

import AddAngleModal from '../Modals/AddAngleModal';
import EditAngleModal from '../Modals/EditAngleModal';

const CoreMenu = ({
    editor, 
    openModal, 
    toggleBold,
    toggleUnderline,
    toggleItalic,

    }) => {
  
  const [angleButtonClicked, setAngleButtonClicked] = useState(false)
  const [anglePlusTextStr, setAnglePlusTextStr] = useState("");

    //Local state to manage flow for adding writer input to text (angle)
  const [angleModalIsOpen, setAngleIsOpen] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [angleText, setAngleText] = useState("");
  const [range, setRange] = useState({from: null, to: null});

    // Functions to manage adding an angle
  const openAngleModal = () => {
      // clearAngleText();
      const { from, to } = editor.state.selection;
      setRange({from, to});
      const selected = editor.state.doc.textBetween(from, to, "");
      setSelectedText(selected)
      setAngleIsOpen(true);
    };

  const closeAngleModal = useCallback(()=> {
      setAngleText("");
      setAnglePlusTextStr("");
      setAngleIsOpen(false);
      setAngleButtonClicked(false);
      if (editor && editor.state && editor.view) {
        // Get the end position of the current selection
        const endPos = editor.state.selection.to;
  
        // Create a new transaction
        const transaction = editor.state.tr;
  
        // Set the selection to a cursor at the end position
        transaction.setSelection(TextSelection.create(transaction.doc, endPos));
  
        // Dispatch the transaction
        editor.view.dispatch(transaction);
    }
    });

  const mockClickEffect = useCallback(()=>{
    console.log('clicked!');
    if(!angleButtonClicked){
      openAngleModal();
    }
    setAngleButtonClicked(!angleButtonClicked);
  });

  return (
    <div className="core-menu"> 
    {/* used to be "editor-menu" */}
    <button
      className="core-menu-btn" //used to be "menu-button"
      onClick={() => editor.chain().focus().undo().run()}
      disabled={!editor.can().undo()}
    >
      <Icons.RotateLeft />
    </button>
    <button
      className="core-menu-btn"
      onClick={() => editor.chain().focus().redo().run()}
      disabled={!editor.can().redo()}
    >
      <Icons.RotateRight />
    </button>

    <button
      className={editor.isActive("link") ? "core-menu-btn is-active" : "core-menu-btn"}
      onClick={openModal}
    >
      <Icons.Link />
    </button>
    <button
      className={editor.isActive("bold") ? "core-menu-btn is-active" : "core-menu-btn"}
      onClick={toggleBold}
    >
      <Icons.Bold />
    </button>
    <button
      className={editor.isActive("underline") ? "core-menu-btn is-active" : "core-menu-btn"}
      onClick={toggleUnderline}
    >
      <Icons.Underline />
    </button>
    <button
      className={editor.isActive("italic") ? "core-menu-btn is-active" : "core-menu-btn"}
      onClick={toggleItalic}
    >
      <Icons.Italic />
    </button>
    <button
      className={angleButtonClicked ? "core-menu-btn is-active" : "core-menu-btn"}
      onClick={mockClickEffect}
    >
      <Icons.Angle />
    </button>
    {angleModalIsOpen && (anglePlusTextStr==="") && <AddAngleModal
        selectedText={selectedText}
        angleText = {angleText}
        setAngleText = {setAngleText}
        setResultStr = {setAnglePlusTextStr}
        closeModal = {closeAngleModal}
        />
      }
     {angleModalIsOpen && (anglePlusTextStr !== "") && <EditAngleModal
        editor = {editor}
        range = {range}
        inputText={selectedText}
        angleText={angleText}
        outputText = {anglePlusTextStr}
        setOutputText = {setAnglePlusTextStr}
        closeModal={closeAngleModal}
        />
      }

  </div>
  )
}

CoreMenu.propTypes = {
    editor: PropTypes.object.isRequired, 
    openModal: PropTypes.func.isRequired, 
    toggleBold: PropTypes.func.isRequired,
    toggleUnderline: PropTypes.func.isRequired,
    toggleItalic: PropTypes.func.isRequired,

}

export default CoreMenu;



// {angleModalIsOpen && (anglePlusTextStr !== "") && <EditAngleModal
// inputText={selectedText}
// outputText = {anglePlusTextStr}
// setOutputText = {setAnglePlusTextStr}
// />}