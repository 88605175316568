import React, {Fragment,useState} from 'react'
import SectionComponent from './SectionComponent'
import Section from './Section';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { InlineWidget } from "react-calendly";


import squares from '../../../img/Home/squares-cropped.jpg'; // Import your background image

import hexagons from '../../../img/Home/hexagons-cropped.jpg'
import circles from '../../../img/Home/tcircles.png'
// import purple from '../../../img/Home/purple.png'
import purple from '../../../img/Home/purple-overlaps-circles.png'
import topImage from '../../../img/Home/editor-still.png'
import fetch from '../../../img/Home/fetch.png'; // Import your overlay image
import highlights from '../../../img/Home/what-matters.png'
import transform from '../../../img/Home/transform-still.png'

import Hero from './Hero';
import Setbacks from './Setbacks';
import TopText from './homeParts/TopText';

const HomePage = () => {
    
    
    const [containerHeights, setContainerHeights] = useState({
        'first':'520px',
        'second': '520px',
        'third': '520px',  
    })
    
  return (
    <Fragment>
        <div >
            <Hero />
        </div>
        <div className=' mt-6'> 
           
            <div className='home-container '>
                <div className='image-wrapper rounded-lg'>
                    <img 
                        className='top-image-style outline outline-offset-2 outline-2 outline-zinc-300 shadow-lg     '
                        decoding="async"
                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 75vw, 70vw"
                        srcSet={`
                        ${topImage}?w=400 400w, 
                        ${topImage}?w=800 800w, 
                        ${topImage}?w=1200 1200w, 
                        ${topImage} 1600w
                        `}
                        src={topImage}
                        alt="A descriptive alt text"
                    />
                </div>
            </div> 
        </div>
        <div className='mt-16'></div>
        <div className='feature1 mt-2'>
            <div className='home-container '>
                <Section
                    containerHeight = {containerHeights['first']}
                    backgroundImage={squares}
                    overlayImage={fetch}
                    additionalStyling={'rotate-12 w-1/3 opacity-30'}
                    titleText={'1. Companies are agents of change'}
                    subheadingText={'Value to clients starts with curated information'}
                    bulletList = {[
                        'Capture the insights relevant to your market',
                        'Fetch written articles with a click'
                    ]}
                    positionStyling={'w-1/2 '}
                />
            </div>
        </div>
        <div className='feature2 mt-2'>
            <div className='home-container '>
                <Section
                    containerHeight = {containerHeights['second']}
                    backgroundImage={hexagons}
                    overlayImage={highlights}
                    additionalStyling = {'-rotate-12 w-1/3 h-auto opacity-30'}
                    titleText={'2. Auto-detecting industry evolution'}
                    subheadingText={'Keep the essence, throw away the rest'}
                    bulletList = {[
                        'Key facts automatically highlighted',
                        'Add highlights, remove and reorder to hone what matters',
                        'Save the information along with related content',
                        
                    ]}
                    positionStyling={'w-2/3 '}
                />
            </div>
        </div>
        <div className='feature3 mt-2'>
            <div className='home-container '>
                <Section
                    containerHeight = {containerHeights['third']} 
                    backgroundImage={purple}
                    overlayImage={transform}
                    additionalStyling = {'-rotate-12 w-1/4 h-auto opacity-30'} 
                    titleText={'3. Educate clients to buy into change'}
                    subheadingText={'Write with a drag and a drop'}
                    bulletList = {[
                        'In one swoop, transform information into cohesive narratives',
                        'Always facts, never fiction',
                        'An exceptional editor helps you weave your message with ease'
                        
                    ]}
                    positionStyling={'w-1/2 '}
                   
                />
            </div>
        </div>
        <div className='mt-16'></div>
        <div className='bg-gradient-to-tl from-fuchsia-200 via-white pb-24'>
            {/* <div className='home-container'> */}
            <div className='home-container '>
            <TopText
                title = {'It’s time clients heard your story.'}
                text1 = {'Written insights clients will love, on repeat'}
                text2 = {'Book that call:'} 
                />
                </div>
            <div  > 
                <InlineWidget 
                    url="https://calendly.com/vered-ewdt/30min"  
                    // style={{ position: "relative", minWidth: "320px", height: "800px", overflow: "hidden" }}
            
                    />
            </div>
        </div>
    </Fragment>
  )
}

export default HomePage

// w-5/6 h-auto

