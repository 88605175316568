import React, {useState} from "react";
import * as Icons from "./Icons";
import LinkDropdown from "./LinkDropdown";
import { LinkM } from "../../../layout/Icons";

function LinkModal(props) {
  const {
    url,
    removeSeen,
    closeModal,
    onChangeUrl,
    onSaveLink,
    onRemoveLink,
    linkList,
    setUrl,
  } = props;

  const [selectedLink, setSelectedLink] = useState({title:"", url: ""});

  const removeLinkFromPara = () => {
    onRemoveLink(url);
  };

  const onChangeSelection = 
    (selectedValue) => {
      let selectedItem = {};
      if(selectedValue==="") {
        selectedItem = {title:"", url: ""}
      } else {
        selectedItem = linkList.find(linkObj => linkObj.url === selectedValue);
      }
      setSelectedLink(selectedItem? selectedItem: {title:"", url: ""});
      setUrl(selectedItem.url);
    };

  return (
    <div className="relative z-10">
       <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          {/* <!--
            Modal panel, show/hide based on modal state.
    
            Entering: "ease-out duration-300"
              From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100 translate-y-0 sm:scale-100"
              To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          --> */}
          <div className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                {/* <h2 className="modal-title">Link Editing</h2> */}
                <LinkM content = "Link Editing" />
                <button className="modal-close" type="button" onClick={closeModal}>
                    <Icons.X />
                </button>

                <LinkDropdown 
                  items = {linkList}
                  selectedItem= {selectedLink}
                  onItemChange= {onChangeSelection}
                  label= "Select a dragged story:"
                />

                <label className="dropdown-label">Or add a new link:</label>
                <input
                    className="dropdown-item"
                    autoFocus
                    value={url}
                    onChange={onChangeUrl}
                />
                </div>
                <div className="bg-slate-100 px-4 py-1 sm:flex sm:flex-row-reverse sm:px-6">
                  <div className="modal-buttons my">
                      {removeSeen && <button className="button-remove font-input" type="button" onClick={removeLinkFromPara}>
                      Remove
                      </button>}
                      <button className="button-save font-input" type="button" onClick={onSaveLink}>
                      Save
                      </button>
                  </div>
              </div>
            </div>
        </div>
           
               
      </div>
    </div>
  );
}

export default LinkModal;