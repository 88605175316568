import React from 'react'
import PropTypes from 'prop-types'

const Bullets = ({bulletList}) => {
  return (
    <ul className="bullet-list">
      {bulletList.map((item, index) => (
        <li className="pt-1" key={index}>{item}</li>
      ))}
    </ul>
  )
}

Bullets.propTypes = {
    bulletList: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default Bullets