import React from 'react'
import { Document, Packer, TextRun, Paragraph, ExternalHyperlink } from "docx";
import PropTypes from 'prop-types'

import SmallBtn from '../layout/Bits/SmallBtn';
import { IconFileDownload} from '@tabler/icons-react';

const Docufy = ({editor}) => {

    const editorToParagraphList = (nodeList) => {
      const paragraphs = nodeList.map((node) => {
        const children = [];
        
        node.forEach((childNode) => {
          // Check for marks and apply formatting
          let style = {};
          let isHyperlink = false;
          let hyperlinkUrl = "";

          if (childNode.marks) {
            childNode.marks.forEach(mark => {
              if (mark.type.name === 'bold') {
                style.bold = true;
              } else if (mark.type.name === 'italic') { //it finds the italic
                style.italics = true; //but this style application is wrong
              } else if (mark.type.name === 'underline') {
                style.underline = true;
              } else if (mark.type.name === 'link') {
                //UPDATE THIS
                isHyperlink = true;
                hyperlinkUrl = mark.attrs.href;
              }
            });
          }

          if (isHyperlink) {
            // Create a hyperlink instead of a regular text run
            children.push(new ExternalHyperlink({
              children: [new TextRun({ text: childNode.text, style: "Hyperlink" })],
              link: hyperlinkUrl,
            }));
          } else {
            // Regular text run for non-hyperlink text
            children.push(new TextRun({
              text: childNode.text,
              ...style
            }));
          }
        });
    
        return new Paragraph({
          children,
          spacing: { after: 200 },
        });
      });
    
      return paragraphs;
    };


    const collectURLsFromParagraphs = (nodeList) => {
      const combinedSourceList = [];
      nodeList.forEach(node => {
        combinedSourceList.push(...node.attrs.sourceList);
      });
      //combinedSourceList is now an array of elements {title, url}

      const uniqueLinks = uniqueByOrder(combinedSourceList);

      // uniqueLinks is now an array of elements as above, but only unique urls
      const childrenList = uniqueLinks
      .filter(item => item.url) // Filter out items where url is falsy. This can either be null, or it's an empty string because url wasn't added in Gist
      .map((item,index) => {
          let linkText = ""
          if (item.title !== ""){ 
            linkText = item.title; 
          } else {
            linkText = item.url;
          }
          const child = new TextRun({text:(index+1) + ". "+ linkText, break:1, style: "Hyperlink",});
          const urlChild = new ExternalHyperlink({
            children: [
                child,
            ],
            link: item.url,
          });
          return urlChild;
      });

      const sourcesHeading = new TextRun({text:"Sources:", break:1, bold: true});
      const combined = [sourcesHeading, ...childrenList];

      const para = new Paragraph({
          children: combined,
          spacing: {
            after: 200, // Adjust the spacing value as needed
          },
        });
      return para;

    };

    const uniqueByOrder = (itemsList)=> {
      const uniqueList = [];
      const seen = new Set();

      for (const item of itemsList) {
        const urlString = item.url;
        if (urlString && !seen.has(urlString)) { //Currently, we're string matching on the URL. It's null on added paragraphs
          uniqueList.push(item);
          seen.add(urlString);
        };
      };
      return uniqueList;
    };


    const generateDocx = () => {


        // declerative styling examples here: https://github.com/dolanmiu/docx/issues/119
        // https://github.com/dolanmiu/docx/blob/master/demo/2-declaritive-styles.ts
        
        const nodeList = editor.state.doc.content.content;

        //1. Generate the paragraph list
        const paragraphList = editorToParagraphList(nodeList);

        //2. Generate the url list
        const LinkParagraph = collectURLsFromParagraphs(nodeList);

        //3. Combine them togather
        const finalList = [...paragraphList, LinkParagraph];

        //4. Set up a document object, pack it, and download.
        const doc = new Document({
            sections: [
              {
                properties: {},
                children: finalList,
              },
            ],
            creator: "Stedili", // Set the creator property,
            
            styles: {
                paragraphStyles: [
                {
                    // Only `name` prop required, `id` not necessary
                    name: 'Normal',
                    run: {
                    size: "12pt",
                    bold: false,
                    font: "Calibri",
                    },
                }
                ],
                hyperlink: {
                  run: {
                      color: "FF0000",
                      underline: {
                          color: "0000FF",
                      },
                  },
              },
            }

          });
    
        Packer.toBlob(doc).then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = "Stedili.docx";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      };
  
    return (

    <SmallBtn 
      buttonFunction={generateDocx} 
      label='Download' 
      formatString={"bg-purple-600 text-white border-solid border border-gray-400 hover:bg-purple-100 hover:text-gray-700"}
      tablerIcon = {IconFileDownload}
    />
  )
}

Docufy.propTypes = {
    editor: PropTypes.object.isRequired,
};

export default Docufy;
