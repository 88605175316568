import React from 'react';
import PropTypes from 'prop-types';

const EditToggle = ({editMode, modeToggle})=> {
    return (
        <div className="flex items-center">
  {/* <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" --> bg-purple-400 */}
            <span className="ml-3 text-sm" >
              <span 
                className={`${!editMode ? 'text-purple-500' : 'text-gray-400'} font-semibold`}>Drag-n-Drop</span>
            </span>
            <button 
              type="button" 
              className={`${editMode ? 'bg-purple-200' : 'bg-gray-200'} edit-toggle ml-3`} 
              role="switch" 
              aria-checked="false" 
              aria-labelledby="editor-mode-label"
              onClick={modeToggle}
              >
              
              {/* <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" --> */}
              <span aria-hidden="true" className={`${editMode ? 'translate-x-5' : 'translate-x-0'} edit-toggle-inside`}></span>
            </button>
            <span className="ml-3 text-sm" >
              <span 
                className={`${editMode ? 'text-purple-500 ' : 'text-gray-400'} font-semibold`}>Text Editor</span>
            </span>
        </div>

    );
};

EditToggle.propTypes = {
    editMode: PropTypes.bool.isRequired,
    modeToggle: PropTypes.func.isRequired
};
    
export default EditToggle;