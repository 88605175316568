import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Popup = ({onAccept, onCancel, blurb, yesButtonText, modalTitle}) => {

    const onClick = () => {
        onAccept();
        onCancel();
    }

    return(
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        {/* <!--
          Background backdrop, show/hide based on modal state.
      
          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        --> */}
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            {/* <!--
              Modal panel, show/hide based on modal state.
      
              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            --> */}
            <div className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-red-600">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                  </svg>
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">{modalTitle}</h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{blurb}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-1 sm:flex sm:flex-row-reverse sm:px-6">
                <button type="button" onClick = {onClick} className=" btn btn-danger">{yesButtonText}</button>
                <button type="button" onClick = {onCancel} className=" btn bg-gray-200 ">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    
    
    
    
    
    )

}

const mapStateToProps = state => ({

});

// Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.

Popup.propTypes = {
onAccept: PropTypes.func.isRequired,
onCancel: PropTypes.func.isRequired,
blurb: PropTypes.string,
yesButtonText: PropTypes.string.isRequired,
modalTitle: PropTypes.string.isRequired
 
};


export default connect(mapStateToProps,{})(Popup);