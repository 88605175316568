import {
    UPDATE_LOCAL_DRAFT,
    CLEAR_LOCAL_DRAFT,
    LOCAL_DRAFT_ERROR
  
  } from './types';

export const updateLocalDraft = (editorState) => async (dispatch) => {
    try {
        dispatch({
          type: UPDATE_LOCAL_DRAFT,
          payload: editorState
        });
      } catch (err) {
        dispatch({
          type: LOCAL_DRAFT_ERROR,
          payload: { msg: 'Failed to update local draft', status: '409' }
        });
      };
};

export const clearLocalDraft = () => async (dispatch) => {
    try {
        dispatch({
          type: CLEAR_LOCAL_DRAFT,
        });
      } catch (err) {
        dispatch({
          type: LOCAL_DRAFT_ERROR,
          payload: { msg: 'Failed to clear local draft', status: '409' }
        });
      };
};