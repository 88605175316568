import React, {useState} from 'react';
import PropTypes from 'prop-types';



function SingleStory({post, index, provided, deleteSnippet}) {

  const {sent, id} = post;

  const [seeDelete, setSeeDelete] = useState(false);

  const updateTools = () => {
    setSeeDelete(!seeDelete);
  }

  const remove = () => {
    deleteSnippet(id);
  };



  return (
    <div 
        onMouseEnter={updateTools}
        onMouseLeave={updateTools}
        className ="modal-picker-element m"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        >

        <div className="font-medium p">{index+1}. {sent}
        {seeDelete && 
          <div className='tools'>
            <button onClick = {remove} className='tool-btn'><i className="fas fa-circle-xmark"></i></button>
          </div>
        }
        </div>
    </div>

  )
}

SingleStory.propTypes = {
  post: PropTypes.object.isRequired
  }

export default SingleStory;

