import React, {Fragment, useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';



const Navbar = ({auth: {isAuthenticated, loading}, logout}) => {
  
  const [isNavbarScrolled, setIsNavbarScrolled] = useState(false);
  const location = useLocation();

  const getLinkClass = (path) => {
    return location.pathname === path ? "active-link" : "";
  };

  const authLinks = (
    <ul>
      <li><Link to="/editor" className={getLinkClass("/editor")}>Editor</Link></li>
      <li><Link to="/collections" className={getLinkClass("/collections")}>Collections</Link></li>
      <li><Link to="/fetch" className={getLinkClass("/fetch")}>Fetch</Link></li>
      <li><a onClick = {logout} href="#!">
      <span className = "hide-sm">Sign out</span></a></li> 
      {/* the className is a css property that hides on a small screen */}
  </ul>
  );

  const guestLinks = (
    <ul>
        <li><Link to="/login">Sign In</Link></li>
    </ul>
  );
  
  
  useEffect(() => {
    // Add a scroll event listener
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsNavbarScrolled(true);
      } else {
        setIsNavbarScrolled(false);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <nav className={`navbar text-md px-32  font-medium font-darkgray ${
      // isNavbarScrolled ? 'bg-white ' : 'py-4' border-b border-gray-300 
      isNavbarScrolled ? 'bg-white shadow-md': ''
    }`}>
    
      <Link to="/" >
      <h1 className={`font-special text-transparent bg-clip-text bg-gradient-to-br from-purple-800 to-orange-500
      ${isNavbarScrolled ? 'text-2xl ': 'text-3xl'}
      `}>
          Stedili
        </h1>
      </Link>

    {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}
  </nav>
  )
}

const mapStateToProps = state => ({
  auth: state.auth
});

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

export default connect(mapStateToProps,{logout})(Navbar);