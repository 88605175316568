import { combineReducers } from 'redux';
import alert from './alert'
import auth from './auth'
import fetch from './fetch'
import gist from './gist'
import bookmark from './bookmark';
import collection from './collection';
import nlp from './nlp';
import draft from './draft';
import angle from './angle';
import scenario from './scenario';

export default combineReducers({
    alert,
    auth,
    fetch,
    gist,
    bookmark,
    collection,
    nlp,
    angle,
    draft,
    scenario,
});