import React, {useState, useEffect, useCallback} from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as Icons from "../Icons";
import { AngleM } from "../../../../layout/Icons";

import { addAngle, clearAngleText } from "../../../../../actions/angle";

function AddAngleModal(props) {
  const {
    selectedText,
    angleText,
    setAngleText,
    setResultStr,
    closeModal,
    addAngle,
    clearAngleText,
    angleGlobalState
  } = props;

  
  useEffect(()=> {
    if(angleGlobalState.plusAngle){
      setResultStr(angleGlobalState.plusAngle.paragraph);
      clearAngleText();
      // closeModal();
    }

},[angleGlobalState.plusAngle]);
  // const mockClickEffect = useCallback(()=>{
  //   console.log('Modal button clicked!');

  // });

  const addAngleClick = useCallback(() => {
    if(selectedText==="" || angleText===""){
      if (selectedText==="" && angleText===""){
        // console.log(angleGlobalState.plusAngle);
        closeModal();
      } else {
      if (selectedText===""){
        // console.log(angleText);
        setAngleText("");
        closeModal();
      } else {
      // angleText==""
        // console.log(selectedText);
        closeModal();
      }
    }
  } else {
    console.log("both: ",selectedText+angleText);
    addAngle(angleText, selectedText);
    // setAngleText("");
    // closeModal();
  }
  });

  return (
    <div className="relative z-10">
       <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          
          <div className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="mb-4">
                <AngleM content = "Add your take" />
                <button className="modal-close" type="button" onClick={closeModal}>
                    <Icons.X />
                </button>
                </div>

                <label className="dropdown-label ">Text to modify</label>
                <textarea
                    className="dropdown-item mb-2"
                    autoFocus
                    value={selectedText}
                    readOnly
                    rows = {8}
                />

                <label className="dropdown-label">Your added angle</label>
                <textarea
                    className="dropdown-item modal-textarea"
                    autoFocus
                    value={angleText}
                    onChange = {(e) => {setAngleText(e.target.value);}}
                    rows = {3}
                />
                
                </div>
                <div className="bg-slate-100 px-4 py-1 sm:flex sm:flex-row-reverse sm:px-6">
                  <div className="modal-buttons my">
                      <button type="button" onClick = {closeModal} className="button-clear font-input">
                      Cancel
                      </button>
                      <button className="button-save font-input" type="button" onClick={addAngleClick}>
                      Add my take
                      </button>
                      
                  </div>
              </div>
            </div>
        </div>
           
               
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  angleGlobalState: state.angle
});

AddAngleModal.propTypes = {
  selectedText:PropTypes.string.isRequired,
  angleText:PropTypes.string.isRequired,
  setAngleText: PropTypes.func.isRequired,
  setResultStr:PropTypes.func.isRequired,
  closeModal:PropTypes.func.isRequired,
  angleGlobalState: PropTypes.object.isRequired,
  addAngle: PropTypes.func.isRequired,
  clearAngleText: PropTypes.func.isRequired
};
export default connect (mapStateToProps, {addAngle, clearAngleText})(AddAngleModal);