import React, {useState} from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Icons from "../../Editor/TipTap/WorkingExample/Icons";
import { Bell } from '../../layout/Icons';

const NewCollectionModal = ({onAccept, onCancel, blurb, yesButtonText, modalTitle}) => {

     //New collection state
     const [newCollection, setNewCollection] = useState ({
        newCollectionTitle:''
    });
    const {newCollectionTitle} = newCollection;


    const onClick = () => {
        onAccept(newCollectionTitle);
        onCancel();
    }

    return(
        <div className="relative z-10">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
         <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
             <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

            <div className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                
                <Bell content = {modalTitle} />
                <button className="modal-close" type="button" onClick={onCancel}>
                    <Icons.X />
                </button>
                
                <div className='mt-4'>
                <input 
                        type = "text"  
                        onChange={e => setNewCollection({...newCollection, newCollectionTitle:e.target.value})}
                        placeholder = "collection name"
                        className='dropdown-item'
                /> 
                </div>
              </div>
              

              <div className="bg-slate-100 px-4 py-1 sm:flex sm:flex-row-reverse sm:px-6">
              <div className='modal-buttons'>
                <button type="button" onClick = {onClick} className="btn btn-success">{yesButtonText}</button>
                <button type="button" onClick = {onCancel} className="btn">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    
    
    
    
    
    )

}

const mapStateToProps = state => ({

});

// Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.

NewCollectionModal.propTypes = {
onAccept: PropTypes.func.isRequired,
onCancel: PropTypes.func.isRequired,
blurb: PropTypes.string,
yesButtonText: PropTypes.string.isRequired,
modalTitle: PropTypes.string.isRequired
 
};


export default connect(mapStateToProps,{})(NewCollectionModal);