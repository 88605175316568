import React, {Fragment, useState, useEffect, useCallback} from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './editor.css';

import useBackground from '../../layout/utils/useBackground';
import {EditorContextProvider} from '../../Editor/EditorContextProvider';

import RibbonMenu from './RibbonMenu';
import Panels from './Panels';

import { getCollections, loadFullCollection, clearLoadedCollection } from '../../../actions/collection';

const EditorPage = ({storedCollectionList, getCollections, loadFullCollection, clearLoadedCollection, loadedCollection}) => {
    // useBackground('#f7f9fb', '#f7f9fb');
    useBackground('#f1f5f9', '#f1f5f9');

    const [isRightPanelVisible, setIsRightPanelVisible] = useState(true);
    const [loaded, setLoaded] = useState(false);
    // const [title, setTitle] = useState("")


    const [selectedCollectionId, setSelectedCollectionId] = useState("")
    const [idToIndexMap, setIdToIndexMap] = useState({});
    
    // Function to handle button click
    const visibleClick = () => {
        setIsRightPanelVisible(!isRightPanelVisible);
    };

    const handleCollectionChange = (selectedValue) => {
        setSelectedCollectionId(selectedValue);
      };
    
    useEffect(()=> {
        clearLoadedCollection();
        getCollections();
        setLoaded(true);
    },[clearLoadedCollection, getCollections]);

    const createIdToIndexMap = useCallback(() => {
        if (loaded) {
          const idToIndex = {};
          storedCollectionList.forEach((item, index) => {
            idToIndex[item._id] = index;
          });
          setIdToIndexMap(idToIndex);
        }
      }, [loaded, storedCollectionList]);
    
    useEffect(() => {
        createIdToIndexMap();
      }, [createIdToIndexMap]);

    const getCollectionData = useCallback(() => {
        if (selectedCollectionId !== "") {
            const dishId = storedCollectionList[idToIndexMap[selectedCollectionId]]['dishPointer']
            loadFullCollection(dishId);
        }

    }, [selectedCollectionId, storedCollectionList, loadFullCollection]);

    useEffect(() => {
        getCollectionData();
      }, [getCollectionData]);



    return (
        <Fragment>
            {loaded && storedCollectionList &&
            <EditorContextProvider>

            <div className="main-container">
                <RibbonMenu 
                    isRightPanelVisible={isRightPanelVisible} 
                    visibleClick = {visibleClick}
                    storedCollectionList={storedCollectionList}
                    selectedCollectionId = {selectedCollectionId} 
                    handleCollectionChange = {handleCollectionChange}    
                />
                <Panels
                  isRightPanelVisible={isRightPanelVisible}   
                />

            </div>

            </EditorContextProvider>
        }
        </Fragment>
    )
    }

const mapStateToProps = state => ({
    storedCollectionList: state.collection.allCollections,
    loadedCollection: state.collection.loadedCollection
    });

EditorPage.propTypes = {
    storedCollectionList: PropTypes.array,
    getCollections: PropTypes.func.isRequired,
    loadFullCollection: PropTypes.func.isRequired,
    clearLoadedCollection: PropTypes.func.isRequired,
    loadedCollection: PropTypes.array
}

export default connect(mapStateToProps,{getCollections, loadFullCollection, clearLoadedCollection})(EditorPage);



// {/* <div className={`panel-container ${isRightPanelVisible ? 'with-right' : 'without-right'}`}>
// <div className={`left-panel s-border ${!isRightPanelVisible ? 'centered' : ''}`}>
//     {/* Content for the left panel */}
// </div>
// {isRightPanelVisible && (
// <div className="right-panel s-border">
//     {/* Right panel content */}
// </div>
// )}
// </div> */}