import React, { useState, useEffect } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import BookmarkViewer from './BookmarkViewer';


const CollectionSpace = ({collectionContent, dropId}) => {

    // conflict between react-beautiful-dnd and React>17. Issue documented here:
    // https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
    
    const [enabled, setEnabled] = useState(false);
  
    const THOUSAND = 1000;
    
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));
      
        return () => {
          cancelAnimationFrame(animation);
          setEnabled(false);
        };
      }, []);
      
    if (!enabled) {
        return null;
      };
    
      
    return enabled ? (
        <Droppable droppableId={dropId}>
           {(provided) => (
              <div
                className="inner-panel bg-white py-1"//used to be summary-block
                {...provided.droppableProps}
                ref={provided.innerRef}
              > 
                {Object.keys(collectionContent)?.map((key, index) => (
                  <Draggable key={collectionContent[key]._id} draggableId={collectionContent[key]._id} index={index*THOUSAND}>
                    {(provided) => (
                      <BookmarkViewer 
                        content = {collectionContent[key]}
                        parentIndex = {index*THOUSAND} 
                        provided={provided}
                        id={collectionContent[key]._id} 
                        />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
           )}
        </Droppable>
      ):
      null;
    };

const mapStateToProps = state => ({
    
});
      

      
CollectionSpace.propTypes = {
    dropId: PropTypes.string.isRequired,
    collectionContent: PropTypes.array.isRequired
};
      

export default connect(mapStateToProps,{})(CollectionSpace);