import React from 'react'
import PropTypes from 'prop-types'
import * as Icons from "../Icons";

import { BubbleMenu } from "@tiptap/react";

const PopMenu = ({
    editor,
    bubbleOpen, 
    openModal, 
    toggleBold,
    toggleUnderline,
    toggleItalic,
}) => {
  
  return (
    <BubbleMenu
        className={` ${bubbleOpen ? 'bubble-menu-dark' : 'bubble-menu-dark-hidden'}`}
        tippyOptions={{ duration: 100 }}
        editor={editor}
        shouldShow={({ editor }) => {
            const isSelectionEmpty = editor.state.selection.empty;
            return !isSelectionEmpty;
          }}
        >
       
        <button
          className={editor.isActive("link") ? "bubble-menu-button is-active" : "bubble-menu-button"}
          onClick={openModal}
        >
          <Icons.Link />
        </button>
        <button
          className={editor.isActive("bold") ? "bubble-menu-button is-active" : "bubble-menu-button"}
          onClick={toggleBold}
        >
          <Icons.Bold />
        </button>
        <button
          className={editor.isActive("underline") ? "bubble-menu-button is-active" : "bubble-menu-button"}
          onClick={toggleUnderline}
        >
          <Icons.Underline />
        </button>
        <button
          className={editor.isActive("italic") ? "bubble-menu-button is-active" : "bubble-menu-button"}
          onClick={toggleItalic}
        >
          <Icons.Italic />
        </button>
      </BubbleMenu>
  )
}

PopMenu.propTypes = {
    editor: PropTypes.object.isRequired,
    bubbleOpen:PropTypes.bool.isRequired, 
    openModal: PropTypes.func.isRequired, 
    toggleBold: PropTypes.func.isRequired,
    toggleUnderline: PropTypes.func.isRequired,
    toggleItalic: PropTypes.func.isRequired,
}

export default PopMenu