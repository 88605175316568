import React, { Fragment, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


import SumSent from './SumSent';
import PropTypes from 'prop-types';


// Never ever nest component declarations!!!!
const DropComponent = ({summaryContent, deleteSnippet}) => {

// conflict between react-beautiful-dnd and React>17. Issue documented here:
// https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  };

  
  return enabled ? (
    <Droppable droppableId="droppable">
       {(provided) => (  
          <div
            className="summary-block bg-white p-1"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >    
            {summaryContent?.map((post, index) => (
              <Draggable key={post.id} draggableId={post.id} index={index}>
                {(provided) => (
                  <SumSent 
                    post = {post}
                    index = {index} 
                    provided={provided} 
                    deleteSnippet = {deleteSnippet}

                    />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
    </Droppable>
  ):
  null;
};


function SummaryBlock({summaryContent, updateSnippet, deleteSnippet}) {

  // console.log(summaryContent);
  const onDragEnd = (result) => {
    if (!result.destination) {
      // The item was dropped outside the list, do nothing
      return;
    }
  
    // Reorder the summaryContent array based on the drag and drop result
    const newSummary = Array.from(summaryContent);
    const [movedItem] = newSummary.splice(result.source.index, 1);
    newSummary.splice(result.destination.index, 0, movedItem);
  
    // Update the state or any other relevant logic
    // Here, we'll assume you have a function to update the state with the new order of summaryContent.
    // You might need to pass this function as a prop from the parent component.
    updateSnippet(newSummary);
  };

  
  return (
    <Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        <DropComponent summaryContent={summaryContent} deleteSnippet = {deleteSnippet}/>
      </DragDropContext>
    </Fragment>
  
  )
}

SummaryBlock.propTypes = {
  summaryContent: PropTypes.array,
  updateSnippet: PropTypes.func.isRequired
  }

export default SummaryBlock;