import {
    FETCH_SUCCESS,
    FETCH_FAIL,
    FETCH_UPDATE,
    FETCH_CLEAN,
} from '../actions/types';

const initialState = {
    content: null,
    fetchSuccess: null,
    loading: true
};

function fetchReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case FETCH_SUCCESS:
            return {
                ...state,
                content: payload,
                fetchSuccess: true,
                loading: false,      
            }
        case FETCH_UPDATE:
            return {
                ...state,
                content: payload,
                fetchSuccess: true,
                      

            }
        case FETCH_FAIL:
            return {
                ...state,
                content: null,
                fetchSuccess: false,
                loading: false,
                
            }
        case FETCH_CLEAN:
            return {
                ...state,
                content: null,
                fetchSuccess: null,
                loading: true,
            }
        default:
            return state;
    }

};

export default fetchReducer;