import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

const TopText = ({title, text1, text2 = ""}) => {
  return (
    <div className='center py-2'>
        <div className='text-center text-4xl font-special text-zinc-800/80'>
        {title}
        </div>
    {text1 && text2 && 
        <Fragment>
            <div className='text-2xl text-center mt-6 font-normal text-zinc-800/80 '>
                {text1}
            </div>
            <div className='text-center mt-2 text-2xl font-normal text-zinc-800/80'>
                {text2}
            </div>  
        </Fragment>
    }
</div>
  )
}

TopText.propTypes = {
    title: PropTypes.string.isRequired,
    text1: PropTypes.string,
    text2: PropTypes.string
}

export default TopText