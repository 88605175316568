import {
    ADD_COLLECTION,
    GET_COLLECTION,
    GET_COLLECTIONS,
    UPDATE_COLLECTION,
    REMOVE_COLLECTION,
    REMOVE_SAVED_COLLECTION,
    COLLECTION_ERROR,
    CLEAR_COLLECTION,
    CLEAR_ALL_COLLECTIONS

    
    
} from '../actions/types'

const initialState = {
    savedCollection: null,
    loadedCollection: null,
    allCollections: [],
    addloading: true,
    deleteloading: true,
    error: {}
};

function collectionReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type){
        case ADD_COLLECTION:
        case UPDATE_COLLECTION:
            return {
                ...state,
                savedCollection: payload,
                addloading: false,

            };
        case GET_COLLECTION:
            return {
                ...state,
                loadedCollection: payload,
                addloading: false,
                
            };
        case GET_COLLECTIONS:
            return {
                ...state,
                loadedCollection: null,
                allCollections: payload,
                addloading: false,
                    
            };
        case REMOVE_COLLECTION:
        case REMOVE_SAVED_COLLECTION:
            return {
                ...state,
                savedCollection: null,
                addloading: true,
                deleteloading: false

            };
        case CLEAR_COLLECTION:
            return {
                ...state,
                loadedCollection: null,
                addloading: true
            };
        case CLEAR_ALL_COLLECTIONS:
            return {
                ...state,
                allCollections: [],
                addloading: true
            };

        case COLLECTION_ERROR:
            return {
                ...state,
                error: payload,
                addloading: false,
                removeloading: false
            };
        default:
            return state;
    }

};

export default collectionReducer;