import React from "react";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';



const CollectionItem = ({cId, cTitle, cUpdate, cSize, dishId}) => {

    // Convert input string to a Date object
    const date = new Date(cUpdate);

    // Extract day, month, and year components
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are 0-indexed
    const year = date.getUTCFullYear();

    // Create the desired format
    const outputDateString = `${day}/${month}/${year}`;
    
    // const [clicked, setClicked] = useState(false);

    const navigate = useNavigate();

    const onClick = () => {
        navigate('/collectionview', {state: {cId, cTitle, outputDateString, cSize, dishId}});
    }

    return (
        <div >
          <div className = "collection_container surround ">
            <div 
                className = "collection_title"
                onClick={onClick}>
                {cTitle}
            </div>
            <div className = "collection_size">
                Containing: {cSize } {cSize === 1 ? 'item' : 'items'}
            </div>
            <div className = "collection_date font-quiet">
                Last updated: {outputDateString}
            </div>
          </div>  
        </div>
    )
}

CollectionItem.propTypes = {
    cId: PropTypes.string.isRequired,
    cTitle: PropTypes.string,
    cUpdate: PropTypes.string,
    cSize: PropTypes.number,
    dishId: PropTypes.string

};

export default CollectionItem;