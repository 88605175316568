import React, { useState, useCallback } from "react";

// // => Tiptap packages
import { EditorContent } from "@tiptap/react";
// import { TextSelection } from '@tiptap/pm/state';


import PopMenu from "./Menus/PopMenu";
import LinkModal from "./LinkModal";
import BulkWriteModal from "./Modals/BulkWriteModal/BulkWriteModal";
import WarningModal from "../../../layout/Bits/WarningModal";

import { useEditorContext } from '../../EditorContextProvider';
import "./styles.css";

const EditorArea = () => {
  // {editor} - used to take that in as a prop, but now receives it through context.

  const { 
    editor,
    range,
    toggleBold,
    toggleUnderline, 
    toggleItalic,
    bulkModalOpen,
    closeBulkModal,
    warningModalOpen,
    closeWarningModal,
    startingList,
    bubbleOpen, 
    setBubbleOpen,
    linkModalIsOpen, 
    setLinkIsOpen,
    removeSeen, 
    setRemoveSeen,
    url, 
    setUrl,
    usedLinkList, 
    setUsedLinkList,
    openLinkModal,
    closeLinkModal,
    saveLink,
    removeLink,

    } = useEditorContext();

  if (!editor) {
    return null;
  }

  return (
    <div className="bg-white"> 
    {/* used to have app class */}
    <div className="editor">
      {/* <div className="relative-container relative"> 
        <CoreMenu 
          editor={editor}
          openModal={openLinkModal}
          toggleBold={toggleBold}
          toggleUnderline={toggleUnderline}
          toggleItalic={toggleItalic}
          />
        
        </div> */}
      <PopMenu 
          editor={editor}
          bubbleOpen = {bubbleOpen}
          openModal={openLinkModal}
          toggleBold={toggleBold}
          toggleUnderline={toggleUnderline}
          toggleItalic={toggleItalic}
          />
      <EditorContent editor={editor} />

      {linkModalIsOpen && <LinkModal
        url={url}
        removeSeen = {removeSeen}
        onRequestClose={closeLinkModal}
        contentLabel="Edit Link Modal"
        closeModal={closeLinkModal}
        onChangeUrl={(e) => {setUrl(e.target.value);}}
        onSaveLink={saveLink}
        onRemoveLink={removeLink}
        linkList = {usedLinkList}
        setUrl = {setUrl}
      />}
      {bulkModalOpen &&  <BulkWriteModal 
        // contentLabel="Edit Link Modal"
        closeModal={closeBulkModal}
        editor = {editor}
        range = {range}
        startingList = {startingList}
      />}
      {
        (warningModalOpen.status && warningModalOpen.type === 'choose-collection')? 
          (<WarningModal
            onAccept={closeWarningModal}
            blurb='Choose a collection in the drop-down to the right.'
            yesButtonText='Okay'
            modalTitle='Please select collection'
          />) : null
        
      }

    </div>
    </div>
  );
}

EditorArea.propTypes = {
    // editor:PropTypes.object.isRequired,
};
export default EditorArea;

