import api from '../utils/api';
import { setAlert } from './alert';

import {
    FETCH_SUCCESS,
    FETCH_FAIL,
    FETCH_CLEAN,
    FETCH_UPDATE,
} from './types';


// fetch and summarise article

export const fetch = (url) => async (dispatch) => {
    const body = { url }
    dispatch ({type: FETCH_CLEAN});

    try {
        const res = await api.post('/fetch', body);

        dispatch({
            type: FETCH_UPDATE,
            payload: res.data
          });

          try {
            const gistRes = await api.post('/gist', res.data);
    
            dispatch({
                type: FETCH_SUCCESS,
                payload: gistRes.data
              });
        
        } catch (err) {
            const errors = err.response.data.errors;
      
            if (errors) {
                errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
            }
      
            dispatch({
                type: FETCH_FAIL
          });
        }
    
    } catch (err) {
        const errors = err.response.data.errors;
  
        if (errors) {
            errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
        }
  
        dispatch({
            type: FETCH_FAIL
      });
    }
};




export const cleanFetch = () => dispatch => {
    dispatch({ type: FETCH_CLEAN}); 
};


// export const complete = () => dispatch => {
//     dispatch({ type: FETCH_DONE}); 
// };
